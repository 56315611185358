import React from "react";
import { connect } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import cornerKickIcon from "../assets/corner-kick.svg";
import goalIcon from "../assets/goal.svg";
import gloveIcon from "../assets/glove.svg";
import rulesIcon from "../assets/rules-icon.png";
import { Mixpanel } from "../constants/mixpanel";

const currentUrl = "/new-bot-type";

const vipBotsRedirectLink = ({ url, isActive, isLoadingPlan }) => {
  if (isLoadingPlan) {
    return currentUrl;
  }

  return isActive ? url : "/checkout";
};

const BotsTypeSelect = (props) => {
  const isLoadingPlan = props.plan.plan === null;
  const isActive = props.plan.status === "active";

  React.useEffect(() => {
    Mixpanel.track("Visitou seleção de tipo de robô");
  }, []);

  return (
    <div>
      <h2 className="vertical-offset-2">Selecione o tipo de robô:</h2>
      <div className="row row-new-bot">
        <div className="col-xs-12 col-lg-3 mobile-card">
          <div className="card card-choose">
            <p className="label-inline-free">Free</p>
            <div className="card-title">Robô de cantos</div>
            <div className="card-image">
              <img icon="flag" src={cornerKickIcon} />
            </div>
            <div className="card-text">
              O robô de cantos vai te alertar sobre partidas que tendem a ter{" "}
              <strong>escanteios</strong> para que você possa fazer suas
              entradas.
            </div>

            <Link to={isLoadingPlan ? currentUrl : "/new-corner-bot"}>
              <button
                className={`btn btn-primary vertical-offset-3 ${
                  isLoadingPlan ? "btn-disabled-color" : ""
                }`}
              >
                Criar robô de cantos
              </button>
            </Link>
          </div>
        </div>

        <div className="col-xs-12 col-lg-3 mobile-card">
          <div className="card card-choose">
            <p className="label-inline-vip">Exclusivo para VIPs</p>
            <div className="card-title">Robô personalizado (Custom bot)</div>
            <div className="card-image">
              <img width={130} icon="flag" src={rulesIcon} />
            </div>
            <div className="card-text">
              O Custom bot permite você criar infinitas possibilidades e padrões
              para achar o jogo perfeito. entradas.
            </div>

            <Link
              to={vipBotsRedirectLink({
                url: "/new-custom-bot",
                isActive,
                isLoadingPlan,
              })}
            >
              <button
                className={`btn btn-primary vertical-offset-3 ${
                  isLoadingPlan ? "btn-disabled-color" : ""
                }`}
              >
                Criar custom bot
              </button>
            </Link>
          </div>
        </div>

        <div className="col-xs-12 col-lg-3 mobile-card">
          <div className="card card-choose">
            <p className="label-inline-vip">Exclusivo para VIPs</p>
            <div className="card-title">Over gols</div>
            <div className="card-image">
              <img icon="flag" src={goalIcon} />
            </div>
            <div className="card-text">
              O robô perfeito para montar sua estratégia de over gols em jogos
              de futebol.
            </div>
            <Link
              to={vipBotsRedirectLink({
                url: "/new-over-goals-bot",
                isActive,
                isLoadingPlan,
              })}
            >
              <button
                className={`btn btn-primary vertical-offset-3 ${
                  isLoadingPlan ? "btn-disabled-color" : ""
                }`}
              >
                Criar robô mais gols
              </button>
            </Link>
          </div>
        </div>

        <div className="col-xs-12 col-lg-3 mobile-card">
          <div className="card card-choose">
            <p className="label-inline-vip">Exclusivo para VIPs</p>
            <div className="card-title">Under gols</div>
            <div className="card-image">
              <img icon="flag" src={gloveIcon} />
            </div>
            <div className="card-text">
              O robô perfeito para montar sua estratégia de under gols em jogos
              de futebol.
            </div>
            <Link to={isLoadingPlan ? currentUrl : "/new-under-goals-bot"}>
              <button
                className={`btn btn-primary vertical-offset-3 ${
                  isLoadingPlan ? "btn-disabled-color" : ""
                }`}
              >
                Criar robô menos gols
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    plan: state.plan,
  };
};

export default connect(mapStateToProps)(withRouter(BotsTypeSelect));
