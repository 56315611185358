const initState = {
  plan: null,
};

const planReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_PLAN_SUCCESS":
      return {
        status: action.status,
        botSize: action.size,
      };
    case "RESET_PLAN_STATE":
      return {
        plan: null,
      };
    default:
      return state;
  }
};

export default planReducer;
