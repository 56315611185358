import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createBotOpenBetFair,
  updateBotOpenBetFair
} from "../store/actions/taskActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import InputRange from "react-input-range";
import RadioToolbar from "./RadioToolbar";
import "react-input-range/lib/css/index.css";
import SweetAlert from "sweetalert2-react";
import { Redirect } from "react-router";
import { Mixpanel } from "../constants/mixpanel";

class BetOpenBetFair extends Component {
  state = {
    brazilList: false,
    spainList: false,
    italyList: false,
    franceList: false,
    internationalList: false,
    redirect: false,
    successMessage: false,
    lists: []
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };
  submitForm = event => {
    event.preventDefault();
    Mixpanel.track("Salvou robo de abertura da BetFair com sucesso");
    const lists = [];
    if (this.state.spainList) {
      lists.push("Espanha");
    }

    if (this.state.italyList) {
      lists.push("Italia");
    }

    if (this.state.franceList) {
      lists.push("França");
    }

    if (this.state.internationalList) {
      lists.push("International");
    }

    this.setState(
      {
        lists,
        successMessage: true
      },
      () => {
        console.log("salvando", this.state);
        if (this.props.match.params.id) {
          this.props.updateBotOpen(this.state, this.props.match.params.id);
        } else {
          this.props.createBotOpen(this.state);
        }
      }
    );
  };
  redirectToDashboard = () => {
    this.setState({
      redirect: true
    });
  };
  componentWillReceiveProps(props) {
    if (props.task && props.task.features) {
      let features = {};
      features.brazilList = props.task.features.brazilList || false;
      features.spainList = props.task.features.spainList || false;
      features.italyList = props.task.features.italyList || false;
      features.franceList = props.task.features.franceList || false;
      features.internationalList =
        props.task.features.internationalList || false;

      this.setState({
        brazilList: features.brazilList,
        spainList: features.spainList,
        italyList: features.italyList,
        franceList: features.franceList,
        internationalList: features.internationalList
      });
    }
  }
  render() {
    if (
      this.props.plan &&
      this.props.plan.status != "active" &&
      this.props.match.path === "/new-open-betfair-bot"
    ) {
      return <Redirect to="/checkout" />;
    }

    Mixpanel.track("Visitou novo robo de aberturas da BetFair");
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    if (this.state.successMessage) {
      Mixpanel.track(
        "Criou/atualizou robo de aberturas da BetFair com sucesso"
      );
      return (
        <SweetAlert
          show={true}
          title="Robô salvo"
          text="Em breve você receberá alertas dele"
          confirmButtonClass="btn btn-primary btn-large"
          buttonsStyling={false}
          type="success"
          onConfirm={this.redirectToDashboard}
        />
      );
    }
    return (
      <div>
        <h2>
          Configure o robô de abertura de jogos no mercado EXCHANGE da BetFair:
        </h2>
        <div className="row">
          <div className="card">
            <p>
              Você será alertado quando um jogo novo aparecer nas
              listas/campeonatos selecionados aqui
            </p>
            <form>
              <div className="form-group">
                <label>Listas principais</label>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="brazilList"
                    id="brazilList"
                    checked={this.state.brazilList}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="brazilList">Lista do Brasil</label>
                </div>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="spainList"
                    id="spainList"
                    checked={this.state.spainList}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="spainList">Lista da Espanha</label>
                </div>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="italyList"
                    id="italyList"
                    checked={this.state.italyList}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="italyList">Lista do Itália</label>
                </div>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="franceList"
                    id="franceList"
                    checked={this.state.franceList}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="franceList">Lista da França</label>
                </div>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="internationalList"
                    id="internationalList"
                    checked={this.state.internationalList}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="internationalList">
                    Lista Internacional (Seleções)
                  </label>
                </div>
              </div>
              <div className="information">
                Para jogos que não tem nessas listas entre em contato pelo chat
              </div>
              <button
                onClick={this.submitForm}
                className="btn btn-primary vertical-offset-4"
              >
                Salvar
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let idProject = ownProps.match.params.id;
  let task = {};
  console.log(state.firestore);
  if (state.firestore.data.tasks) {
    task = state.firestore.data.tasks[idProject];
  }
  return {
    auth: state.firebase.auth,
    task: task,
    plan: state.plan
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  let idProject = ownProps.match.params.id;
  return {
    createBotOpen: botCorner => dispatch(createBotOpenBetFair(botCorner)),
    updateBotOpen: botCorner =>
      dispatch(updateBotOpenBetFair(botCorner, idProject))
  };
};
export default compose(
  firestoreConnect(props => {
    let idProject = props.match.params.id;
    console.log("idddddddd" + idProject);

    if (!idProject) {
      return [];
    } else {
      return [
        {
          collection: "tasks",
          doc: idProject
        }
      ];
    }
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(BetOpenBetFair);
