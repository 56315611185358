import React, { Component } from "react";
import { connect } from "react-redux";
import {
    createBotOpenFifa,
    updateBotOpenFifa,
    deleteBot
} from "../store/actions/taskActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import InputRange from "react-input-range";
import RadioToolbar from "./RadioToolbar";
import "react-input-range/lib/css/index.css";
import SweetAlert from "sweetalert2-react";
import { Redirect } from "react-router";
import { Mixpanel } from "../constants/mixpanel";

class BotOpenNew extends Component {
    state = {
        europeEliteList: false,
        brazilList: false,

        lists: []
    };
    deleteBot = event => {
        event.preventDefault();
        this.props.deleteBot(this.props.match.params.id);
        this.setState({ deleteMessage: true });
    };

    handleInputChange = event => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };
    submitForm = event => {
        event.preventDefault();
        Mixpanel.track("Salvou robo de abertura com sucesso");
        const lists = [];
        if (this.state.livearena10) {
            lists.push("Esoccer Live Arena - 10 mins play");
        }

        if (this.state.fufv12) {
            lists.push("Esoccer FUFV League - 12 mins play");
        }

        if (this.state.battle8) {
            lists.push("Esoccer Battle - 8 mins play");
        }

        if (this.state.ligapro12) {
            lists.push("Esoccer Liga Pro - 12 mins play");
        }
      
        this.setState(
            {
                lists,
                successMessage: true
            },
            () => {
                console.log("salvando", this.state);
                if (this.props.match.params.id) {
                    this.props.updateBotOpen(this.state, this.props.match.params.id);
                } else {
                    this.props.createBotOpen(this.state);
                }
            }
        );
    };
    redirectToDashboard = () => {
        this.setState({
            redirect: true,
            deleteMessage: false
        });
    };
    componentWillReceiveProps(props) {
        if (props.task && props.task.features) {
            let features = {};
            features.livearena10 = props.task.features.livearena10 || false;
            features.fufv12 = props.task.features.fufv12 || false;
            features.battle8 = props.task.features.battle8 || false;
            features.ligapro12 = props.task.features.ligapro12 || false;
            

            this.setState({
                livearena10: features.livearena10,
                fufv12: features.fufv12,
                battle8: features.battle8,
                ligapro12: features.ligapro12
            });
        }
    }
    render() {
        let disabledCheck = true;

        if (this.state.deleteMessage) {
            Mixpanel.track("Excluiu robo com sucesso");
            return (
                <SweetAlert
                    show={true}
                    title="Robô excluído"
                    confirmButtonClass="btn btn-primary btn-large"
                    buttonsStyling={false}
                    type="warning"
                    onConfirm={this.redirectToDashboard}
                />
            );
        }

        let deleteButton = null;
        if (this.props.match.params.id) {
            deleteButton = (
                <button onClick={this.deleteBot} className="btn btn-outline">
                    Excluir
                </button>
            );
        }

       

       

        Mixpanel.track("Visitou novo robo de aberturas");
        if (this.state.redirect) {
            return <Redirect to="/" />;
        }
        if (this.state.successMessage) {
            Mixpanel.track("Criou/atualizou robo de aberturas com sucesso");
            return (
                <SweetAlert
                    show={true}
                    title="Robô salvo"
                    text="Em breve você receberá alertas dele"
                    confirmButtonClass="btn btn-primary btn-large"
                    buttonsStyling={false}
                    type="success"
                    onConfirm={this.redirectToDashboard}
                />
            );
        }
        return (
            <div>
                <h2>Configure o robô de aberturas Fifa(esports):</h2>
                <div className="row">
                    <div className="card">
                        <p>
                            Você será alertado quando um jogo novo aparecer nas
                            listas/campeonatos selecionados aqui
            </p>
                        <form>
                            <div className="form-group">
                                <label>
                                    Campeonatos<span class="label-inline-free">Free</span>
                                </label>
                                <div className="checkbox-container">
                                    <input
                                        type="checkbox"
                                        name="livearena10"
                                        id="livearena10"
                                        checked={this.state.livearena10}
                                        onChange={this.handleInputChange}
                                    />
                                    <label htmlFor="livearena10">Esoccer Live Arena - 10 mins play</label>
                                </div>
                                <div className="checkbox-container">
                                    <input
                                        type="checkbox"
                                        name="fufv12"
                                        id="fufv12"
                                        checked={this.state.fufv12}
                                        onChange={this.handleInputChange}
                                    />
                                    <label htmlFor="fufv12">Esoccer FUFV League - 12 mins play</label>
                                </div>
                                <div className="checkbox-container">
                                    <input
                                        type="checkbox"
                                        name="battle8"
                                        id="battle8"
                                        checked={this.state.battle8}
                                        onChange={this.handleInputChange}
                                    />
                                    <label htmlFor="battle8">Esoccer Battle - 8 mins play</label>
                                </div>
                                <div className="checkbox-container">
                                    <input
                                        type="checkbox"
                                        name="ligapro12"
                                        id="ligapro12"
                                        checked={this.state.ligapro12}
                                        onChange={this.handleInputChange}
                                    />
                                    <label htmlFor="ligapro12">Esoccer Liga Pro - 12 mins play</label>
                                </div>
                            </div>


                            <div class="information">
                                Para jogos que não tem nessas listas entre em contato pelo chat
                            </div>

                            <div className="row">
                                <div className="col-xs-8 col-sm-2">
                                    <button onClick={this.submitForm} className="btn btn-primary">
                                        Salvar
                  </button>
                                </div>

                                <div className="col-xs-4 col-sm-2">{deleteButton}</div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let idProject = ownProps.match.params.id;
    let task = {};
    if (state.firestore.data.tasks) {
        task = state.firestore.data.tasks[idProject];
    }
    return {
        auth: state.firebase.auth,
        task: task,
        plan: state.plan
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    let idProject = ownProps.match.params.id;
    return {
        createBotOpen: botCorner => dispatch(createBotOpenFifa(botCorner)),
        deleteBot: botId => dispatch(deleteBot(botId)),
        updateBotOpen: botCorner => dispatch(updateBotOpenFifa(botCorner, idProject))
    };
};
export default compose(
    firestoreConnect(props => {
        let idProject = props.match.params.id;

        if (!idProject) {
            return [];
        } else {
            return [
                {
                    collection: "tasks",
                    doc: idProject
                }
            ];
        }
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(BotOpenNew);
