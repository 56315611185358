import React, { Component } from "react";
import { connect } from "react-redux";
import failed from "../assets/failed.svg";
import { Link } from "react-router-dom";
import { Mixpanel } from "../constants/mixpanel";

const FailPayment = () => {
  Mixpanel.track("Falhou no pagamento");
  return (
    <div className="row center-xs">
      <div className="col-sm-12 col-md-6">
        <div className="card center">
          <h2>Falha no pagamento</h2>
          <img width="120px" src={failed} />
          <div className="center-xs center">
            <Link to="/checkout">
              <button class="btn btn-primary vertical-offset-3">
                Tentar novamente
              </button>
            </Link>
            <Link to="/dashboard">
              <button class="btn btn-outline vertical-offset-1">
                Voltar para o dashboard
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FailPayment;
