import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createBotOpen,
  updateBotOpen,
  deleteBot,
} from "../store/actions/taskActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import InputRange from "react-input-range";
import RadioToolbar from "./RadioToolbar";
import "react-input-range/lib/css/index.css";
import SweetAlert from "sweetalert2-react";
import { Redirect } from "react-router";
import { Mixpanel } from "../constants/mixpanel";

class BotOpenNew extends Component {
  state = {
    europeEliteList: false,
    brazilList: false,
    brazilAltList: false,
    australianList: false,
    hollandList: false,
    italyList: false,
    portugalList: false,
    UKList: false,
    germanyList: false,
    spainList: false,
    usaList: false,
    asianList: false,
    redirect: false,
    successMessage: false,
    lists: [],
  };
  deleteBot = (event) => {
    event.preventDefault();
    this.props.deleteBot(this.props.match.params.id);
    this.setState({ deleteMessage: true });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };
  submitForm = (event) => {
    event.preventDefault();
    Mixpanel.track("Salvou robo de abertura com sucesso");
    const lists = [];
    if (this.state.brazilList) {
      lists.push("Lista do Brasil");
    }

    if (this.state.australianList) {
      lists.push("Lista da Australia");
    }

    if (this.state.asianList) {
      lists.push("Lista da Asia");
    }

    if (this.state.germanyList) {
      lists.push("Lista da Alemanha");
    }

    if (this.state.portugalList) {
      lists.push("Lista de Portugal");
    }

    if (this.state.spainList) {
      lists.push("Lista da Espanha");
    }

    if (this.state.italyList) {
      lists.push("Lista da Italia");
    }

    if (this.state.UKList) {
      lists.push("Lista do Reino Unido");
    }

    if (this.state.usaList) {
      lists.push("Lista da America do Norte");
    }

    if (this.state.europeEliteList) {
      lists.push("Lista da Elite Europeia");
    }

    if (this.state.eliteSouthAmericaList) {
      lists.push("Lista da Elite Sulamericana");
    }

    if (this.state.brazilAltList) {
      lists.push("Brasil - Varzea");
    }

    if (this.state.internationalList) {
      lists.push("Lista Internacional");
    }

    if (this.state.argentinaList) {
      lists.push("Lista da Argentina");
    }

    if (this.state.russiaList) {
      lists.push("Lista da Russia");
    }

    if (this.state.ukraineList) {
      lists.push("Lista da Ucrania");
    }

    this.setState(
      {
        lists,
        successMessage: true,
      },
      () => {
        console.log("salvando", this.state);
        if (this.props.match.params.id) {
          this.props.updateBotOpen(this.state, this.props.match.params.id);
        } else {
          this.props.createBotOpen(this.state);
        }
      }
    );
  };
  redirectToDashboard = () => {
    this.setState({
      redirect: true,
      deleteMessage: false,
    });
  };
  componentWillReceiveProps(props) {
    if (props.task && props.task.features) {
      let features = {};
      features.europeEliteList = props.task.features.europeEliteList || false;
      features.brazilList = props.task.features.brazilList || false;
      features.australianList = props.task.features.australianList || false;
      features.UKList = props.task.features.UKList || false;
      features.asianList = props.task.features.asianList || false;
      features.usaList = props.task.features.usaList || false;
      features.spainList = props.task.features.spainList || false;
      features.germanyList = props.task.features.germanyList || false;
      features.portugalList = props.task.features.portugalList || false;
      features.hollandList = props.task.features.hollandList || false;
      features.italyList = props.task.features.italyList || false;
      features.eliteSouthAmericaList =
        props.task.features.eliteSouthAmericaList || false;
      features.brazilAltList = props.task.features.brazilAltList || false;
      features.internationalList =
        props.task.features.internationalList || false;

      features.argentinaList = props.task.features.argentinaList || false;
      features.russiaList = props.task.features.russiaList || false;
      features.ukraineList = props.task.features.ukraineList || false;

      this.setState({
        europeEliteList: features.europeEliteList,
        brazilList: features.brazilList,
        australianList: features.australianList,
        UKList: features.UKList,
        asianList: features.asianList,
        usaList: features.usaList,
        spainList: features.spainList,
        germanyList: features.germanyList,
        portugalList: features.portugalList,
        italyList: features.italyList,
        hollandList: features.hollandList,
        eliteSouthAmericaList: features.eliteSouthAmericaList,
        brazilAltList: features.brazilAltList,
        internationalList: features.internationalList,
        argentinaList: features.argentinaList,
        russiaList: features.russiaList,
        ukraineList: features.ukraineList,
      });
    }
  }
  render() {
    let disabledCheck = true;

    if (this.state.deleteMessage) {
      Mixpanel.track("Excluiu robo com sucesso");
      return (
        <SweetAlert
          show={true}
          title="Robô excluído"
          confirmButtonClass="btn btn-primary btn-large"
          buttonsStyling={false}
          type="warning"
          onConfirm={this.redirectToDashboard}
        />
      );
    }

    let deleteButton = null;
    if (this.props.match.params.id) {
      deleteButton = (
        <button onClick={this.deleteBot} className="btn btn-outline">
          Excluir
        </button>
      );
    }

    if (this.props.plan && this.props.plan.status === "active") {
      disabledCheck = false;
    }

    if (
      this.props.plan &&
      this.props.plan.botSize > 1 &&
      this.props.plan.status != "active" &&
      !this.props.match.params.id
    ) {
      return <Redirect to="/checkout" />;
    }

    Mixpanel.track("Visitou novo robo de aberturas");
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    if (this.state.successMessage) {
      Mixpanel.track("Criou/atualizou robo de aberturas com sucesso");
      return (
        <SweetAlert
          show={true}
          title="Robô salvo"
          text="Em breve você receberá alertas dele"
          confirmButtonClass="btn btn-primary btn-large"
          buttonsStyling={false}
          type="success"
          onConfirm={this.redirectToDashboard}
        />
      );
    }
    return (
      <div>
        <h2>Configure o robô de aberturas:</h2>
        <div className="row">
          <div className="card">
            <p>
              Você será alertado quando um jogo novo aparecer nas
              listas/campeonatos selecionados aqui
            </p>
            <form>
              <div className="form-group">
                <label>
                  Listas principais<span class="label-inline-free">Free</span>
                </label>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="europeEliteList"
                    id="europeEliteList"
                    checked={this.state.europeEliteList}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="europeEliteList">Lista Elite Européia</label>
                </div>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="brazilList"
                    id="brazilList"
                    checked={this.state.brazilList}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="brazilList">
                    Lista do Brasil Principal (Série A e B)
                  </label>
                </div>
              </div>

              <div className="form-group form-vip">
                <label>
                  Listas alternativas
                  <span class="label-inline-vip">VIP</span>
                </label>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="brazilAltList"
                    id="brazilAltList"
                    checked={this.state.brazilAltList}
                    onChange={this.handleInputChange}
                    disabled={disabledCheck}
                  />

                  <label htmlFor="brazilAltList">
                    Lista do Brasil alternativa (Séries inferiores e copas)
                  </label>
                </div>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="usaList"
                    id="usaList"
                    checked={this.state.usaList}
                    onChange={this.handleInputChange}
                    disabled={disabledCheck}
                  />

                  <label htmlFor="usaList">Lista dos Estados Unidos</label>
                </div>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="asianList"
                    id="asianList"
                    checked={this.state.asianList}
                    onChange={this.handleInputChange}
                    disabled={disabledCheck}
                  />
                  <label htmlFor="asianList">Lista da Ásia </label>
                </div>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="australianList"
                    id="australianList"
                    checked={this.state.australianList}
                    onChange={this.handleInputChange}
                    disabled={disabledCheck}
                  />

                  <label htmlFor="australianList">Lista da Austrália</label>
                </div>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={this.state.hollandList}
                    name="hollandList"
                    id="hollandList"
                    onChange={this.handleInputChange}
                    disabled={disabledCheck}
                  />

                  <label htmlFor="hollandList">Lista da Holanda</label>
                </div>

                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="italyList"
                    id="italyList"
                    checked={this.state.italyList}
                    onChange={this.handleInputChange}
                    disabled={disabledCheck}
                  />
                  <label htmlFor="italyList">Lista da Itália</label>
                </div>

                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="portugalList"
                    id="portugalList"
                    checked={this.state.portugalList}
                    onChange={this.handleInputChange}
                    disabled={disabledCheck}
                  />
                  <label htmlFor="portugalList">Lista de Portugal</label>
                </div>

                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="UKList"
                    id="UKList"
                    checked={this.state.UKList}
                    onChange={this.handleInputChange}
                    disabled={disabledCheck}
                  />

                  <label htmlFor="UKList">Lista do Reino Unido</label>
                </div>

                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="germanyList"
                    id="germanyList"
                    checked={this.state.germanyList}
                    onChange={this.handleInputChange}
                    disabled={disabledCheck}
                  />
                  <label htmlFor="germanyList">Lista da Alemanha</label>
                </div>

                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="spainList"
                    id="spainList"
                    checked={this.state.spainList}
                    onChange={this.handleInputChange}
                    disabled={disabledCheck}
                  />
                  <label htmlFor="spainList">Lista da Espanha</label>
                </div>

                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="eliteSouthAmericaList"
                    id="eliteSouthAmericaList"
                    checked={this.state.eliteSouthAmericaList}
                    onChange={this.handleInputChange}
                    disabled={disabledCheck}
                  />
                  <label htmlFor="eliteSouthAmericaList">
                    Lista da Elite Sulamericana (Libertadores e Copa
                    Sulamericana)
                  </label>
                </div>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="internationalList"
                    id="internationalList"
                    checked={this.state.internationalList}
                    onChange={this.handleInputChange}
                    disabled={disabledCheck}
                  />
                  <label htmlFor="internationalList">
                    Lista Internacional (Seleções)
                  </label>
                </div>

                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="argentinaList"
                    id="argentinaList"
                    checked={this.state.argentinaList}
                    onChange={this.handleInputChange}
                    disabled={disabledCheck}
                  />
                  <label htmlFor="argentinaList">Lista da Argentina</label>
                </div>

                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="russiaList"
                    id="russiaList"
                    checked={this.state.russiaList}
                    onChange={this.handleInputChange}
                    disabled={disabledCheck}
                  />
                  <label htmlFor="russiaList">Lista da Rússia</label>
                </div>

                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="ukraineList"
                    id="ukraineList"
                    checked={this.state.ukraineList}
                    onChange={this.handleInputChange}
                    disabled={disabledCheck}
                  />
                  <label htmlFor="ukraineList">Lista da Ucrânia</label>
                </div>
              </div>

              <div class="information">
                Para jogos que não tem nessas listas entre em contato pelo chat
              </div>

              <div className="row">
                <div className="col-xs-8 col-sm-2">
                  <button onClick={this.submitForm} className="btn btn-primary">
                    Salvar
                  </button>
                </div>

                <div className="col-xs-4 col-sm-2">{deleteButton}</div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let idProject = ownProps.match.params.id;
  let task = {};
  if (state.firestore.data.tasks) {
    task = state.firestore.data.tasks[idProject];
  }
  return {
    auth: state.firebase.auth,
    task: task,
    plan: state.plan,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  let idProject = ownProps.match.params.id;
  return {
    createBotOpen: (botCorner) => dispatch(createBotOpen(botCorner)),
    deleteBot: (botId) => dispatch(deleteBot(botId)),
    updateBotOpen: (botCorner) => dispatch(updateBotOpen(botCorner, idProject)),
  };
};
export default compose(
  firestoreConnect((props) => {
    let idProject = props.match.params.id;

    if (!idProject) {
      return [];
    } else {
      return [
        {
          collection: "tasks",
          doc: idProject,
        },
      ];
    }
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(BotOpenNew);
