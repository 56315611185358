import mixpanel from "mixpanel-browser";

if (window.location.hostname === "localhost")
  mixpanel.init("b6178aaae6711f2b6b40cf1334fb3297");
else mixpanel.init("0a545c3e826327a84bfededcf316573c");

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
