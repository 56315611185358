import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  createCustomBot,
  updateCustomBot,
  deleteBot,
} from "../store/actions/taskActions";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import InputRange from "react-input-range";
import RadioToolbar from "./RadioToolbar";
import "react-input-range/lib/css/index.css";
import { useForm, Controller } from "react-hook-form";
import SweetAlert from "sweetalert2-react";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import BotCustomRuleItem from "./BotCustomRuleItem";
import Checkbox from "./Checkbox";
import Modal from "react-modal";
import YouTube from "react-youtube";

const removePreSelectedLeaguesFromDatabase = ({
  preSelectedLeages,
  leaguesFromDatabase,
}) => {
  if (!leaguesFromDatabase) {
    return [];
  }

  let result = [];

  for (let index = 0; index < leaguesFromDatabase.length; index++) {
    const league = leaguesFromDatabase[index];
    const isFromPreselectedLeagues = preSelectedLeages.some(
      (l) => l.value === league.value
    );
    if (isFromPreselectedLeagues) {
      continue;
    }
    result.push(league);
  }

  return result;
};

const removeLeaguesFromPreSelectedOptions = ({
  preSelectedLeages,
  leaguesFromDatabase,
}) => {
  let result = leaguesFromDatabase ? [...leaguesFromDatabase] : [];

  for (let index = 0; index < preSelectedLeages.length; index++) {
    const league = preSelectedLeages[index];
    result = result.filter((l) => l.value !== league.value);
  }

  return result;
};

const deduplicateLeagues = (leagues) => {
  const result = [];

  for (let index = 0; index < leagues.length; index++) {
    const league = leagues[index];
    const alreadyIncluded = result.some((l) => l.value === league.value);
    if (alreadyIncluded) {
      continue;
    }
    result.push(league);
  }

  return result;
};

const selectYouthLeagues = (leagues) => {
  const leaguesToInclude = [
    "youth",
    "u23",
    "u22",
    "u21",
    "u20",
    "u19",
    "u18",
    "u17",
    "u16",
  ];

  return leagues.filter((league) => {
    const hasLabel = !!league.label;

    if (!hasLabel) {
      return false;
    }

    for (let index = 0; index < leaguesToInclude.length; index++) {
      const leagueToInclude = leaguesToInclude[index];
      const shouldInclude = league.label
        .toLowerCase()
        .includes(leagueToInclude);

      if (shouldInclude) {
        return true;
      }
    }

    return false;
  });
};

const selectFemaleLeagues = (leagues) => {
  const leaguesToInclude = [
    "feminina",
    "femenil",
    "femenina",
    "womens",
    "women",
  ];

  return leagues.filter((league) => {
    const hasLabel = !!league.label;

    if (!hasLabel) {
      return false;
    }

    for (let index = 0; index < leaguesToInclude.length; index++) {
      const leagueToInclude = leaguesToInclude[index];
      const shouldInclude = league.label
        .toLowerCase()
        .includes(leagueToInclude);

      if (shouldInclude) {
        return true;
      }
    }

    return false;
  });
};

const selectMaleProLeagues = (leagues) => {
  const leaguesToExclude = [
    "feminina",
    "femenil",
    "femenina",
    "womens",
    "women",
    "youth",
    "u23",
    "u22",
    "u21",
    "u20",
    "u19",
    "u18",
    "u17",
    "u16",
  ];

  return leagues.filter((league) => {
    const hasLabel = !!league.label;

    if (!hasLabel) {
      return false;
    }

    for (let index = 0; index < leaguesToExclude.length; index++) {
      const leagueToExclude = leaguesToExclude[index];
      const shouldExclude = league.label
        .toLowerCase()
        .includes(leagueToExclude);

      if (shouldExclude) {
        return false;
      }
    }

    return true;
  });
};

const NewRuleForm = ({ addNewRule }) => {
  const [attributeLabel, setAttributeLabel] = useState("Gols");
  const [stepRule, setStepRule] = useState(1);
  const [stepMaxValueRule, setStepMaxValueRule] = useState({ max: 150 });

  const { register, handleSubmit, control, getValues, setValue } = useForm({
    defaultValues: { team: "teamHome", attribute: "goals", type: "equal" },
  });
  const onSubmit = (data) => {
    addNewRule(data);
  };

  const getAttributeLabel = () => {
    const attributeNames = {
      goals: "Gols",
      goalChances: "Chance de gol",
      yellowCards: "Cartões Amarelos",
      redCards: "Cartões Vermelhos",
      shots: "Chutes no total",
      shotsOn: "Chutes no gol",
      shotsOff: "Chutes pra fora",
      possession: "Posse de bola",
      corners: "Escanteios",
      attacks: "Ataques",
      attacksDanger: "Ataques perigosos",
      attacksPerMinute: "Ataques perigosos por minuto",
      handicap: "Handicap inicial (favoritismo)",
      goalsLine: "Linha de gols",
      goalsAdvantage: "Gols de vantagem",
    };

    const attributeValues = {
      goals: { min: 0, max: 10, gap: 1 },
      goalChances: { min: 0, max: 200, gap: 1 },
      yellowCards: { min: 0, max: 20, gap: 1 },
      redCards: { min: 0, max: 20, gap: 1 },
      shots: { min: 0, max: 80, gap: 1 },
      shotsOn: { min: 0, max: 30, gap: 1 },
      shotsOff: { min: 0, max: 80, gap: 1 },
      possession: { min: 0, max: 100, gap: 1 },
      corners: { min: 0, max: 30, gap: 1 },
      attacks: { min: 0, max: 200, gap: 1 },
      attacksDanger: { min: 0, max: 200, gap: 1 },
      attacksPerMinute: { min: 0, max: 3, gap: 0.05 },
      handicap: { min: 0, max: 5, gap: 0.25 },
      goalsLine: { min: 0, max: 5, gap: 0.25 },
      goalsAdvantage: { min: 0, max: 10, gap: 1 },
    };

    setStepRule(attributeValues[getValues("attribute")].gap);

    setStepMaxValueRule({ max: attributeValues[getValues("attribute")].max });
    setAttributeLabel(attributeNames[getValues("attribute") || "goals"]);
    setValue("value", 1);
  };

  return (
    <div className="form-group form-vip">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>Selecione a equipe:</label>
          <RadioToolbar>
            <input
              name="team"
              type="radio"
              value="teamAny"
              id="teamAny"
              ref={register({ required: true })}
            />
            <label htmlFor="teamAny">Qualquer</label>
            <input
              name="team"
              type="radio"
              value="teamBoth"
              id="teamBoth"
              ref={register({ required: true })}
            />
            <label htmlFor="teamBoth">Ambos</label>
            <input
              name="team"
              type="radio"
              value="teamHome"
              id="teamHome"
              ref={register({ required: true })}
            />
            <label htmlFor="teamHome">Time da casa</label>
            <input
              name="team"
              type="radio"
              value="teamAway"
              id="teamAway"
              ref={register({ required: true })}
            />
            <label htmlFor="teamAway">Time visitante</label>
          </RadioToolbar>
        </div>

        <div className="form-group">
          <label>Selecione o atributo:</label>
          <select
            name="attribute"
            onChange={getAttributeLabel}
            ref={register({ required: true })}
          >
            <option value="goals">Gols</option>
            <option value="goalsAdvantage">Gols de vantagem</option>
            <option value="goalsLine">Linha de gols</option>
            <option value="handicap">Handicap inicial</option>
            <option value="goalChances">Chance de gol</option>
            <option value="shots">Chutes no total</option>
            <option value="shotsOn">Chutes no gol</option>
            <option value="shotsOff">Chutes pra fora</option>
            <option value="corners">Escanteios</option>
            <option value="possession">Posse de bola</option>
            <option value="attacksPerMinute">
              Ataques perigosos por minuto
            </option>
            <option value="attacksDanger">Ataques perigosos</option>
            <option value="attacks">Ataques</option>
            <option value="redCards">Cartões vermelhos</option>
            <option value="yellowCards">Cartões amarelos</option>
          </select>
        </div>

        <div className="form-group">
          <label>Tipo: </label>
          <RadioToolbar>
            <input
              name="type"
              type="radio"
              id="less"
              value="less"
              ref={register({ required: true })}
            />
            <label htmlFor="less">Menor</label>
            <input
              name="type"
              type="radio"
              value="lessEqual"
              id="lessEqual"
              ref={register({ required: true })}
            />
            <label htmlFor="lessEqual">Menor igual</label>
            <input
              name="type"
              type="radio"
              value="equal"
              id="equal"
              ref={register({ required: true })}
            />
            <label htmlFor="equal">Igual</label>
            <input
              name="type"
              type="radio"
              value="moreEqual"
              id="moreEqual"
              ref={register({ required: true })}
            />
            <label htmlFor="moreEqual">Maior igual</label>
            <input
              name="type"
              type="radio"
              value="more"
              id="more"
              ref={register({ required: true })}
            />
            <label htmlFor="more">Maior</label>
          </RadioToolbar>
        </div>
        <div className="form-group">
          <label htmlFor="value">
            Valor de <span className="highlight">{attributeLabel}</span>
          </label>
          <Controller
            name="value"
            control={control}
            defaultValue={1}
            render={({ onChange, value }) => (
              <InputRange
                id="value"
                onChange={onChange}
                formatLabel={(value) => {
                  if (stepRule === 0.25 || stepRule === 0.05)
                    return `${value.toFixed(2)}`;
                  else return `${value}`;
                }}
                value={value}
                maxValue={stepMaxValueRule.max}
                minValue={0}
                step={stepRule}
                draggableTrack
              />
            )}
          />
        </div>
        <div className="row add-rule-btn-wrapper">
          <button className="btn btn-primary" type="submit">
            + Adicionar regra
          </button>
        </div>
      </form>
    </div>
  );
};

const selectLineByMarket = (market) => {
  if (!market) {
    return {
      placeholder: "Selecione um mercado para poder definir uma linha",
      options: [],
    };
  }

  const mapByMarket = {
    goals: {
      placeholder: "Linha de gols",
      options: [
        { value: 0.5, label: "Limite de gols +0.5 (sair mais um gol)" },
        { value: 0.75, label: "Limite de gols +0.75" },
        { value: 1, label: "Limite de gols +1.0 (sair um gol ou mais)" },
        { value: 1.25, label: "Limite de gols +1.25" },
        { value: 1.5, label: "Limite de gols +1.5" },
      ],
    },
    corners: {
      placeholder: "Linha de escanteios",
      options: [
        {
          value: 0.5,
          label: "Limite de escanteio +0.5 (sair mais um escanteio)",
        },
        {
          value: 1,
          label: "Limite de escanteio +1.0 (sair mais um escanteio ou mais)",
        },
        {
          value: 1.5,
          label: "Limite de escanteio +1.5 (sair mais 2 escanteios)",
        },
        {
          value: 2,
          label: "Limite de escanteio +2.0 (sair mais 2 escanteios ou mais)",
        },
      ],
    },
  };

  return mapByMarket[market];
};

const BotCustom = ({
  createCustomBot,
  Itask = {},
  id,
  updateCustomBot,
  deleteCustomBot,
  scorebing_leagues,
  plan,
  auth,
}) => {
  if (Itask.features === undefined) {
    Itask = {
      features: { description: "" },
    };
  }

  let history = useHistory();
  const [newRule, setNewRule] = useState(false);
  const [task, setTask] = useState({
    features: Itask.features || {},
    description: Itask.features.description || "",
    fav: Itask.features.fav || "BOTH",
    isDraw: Itask.features.isDraw || false,
    notDraw: Itask.features.notDraw || false,
    isOnlyWithCornersMarket: Itask.features.isOnlyWithCornersMarket || false,
    leagues: Itask.features.leagues || [],
  });
  const [period, setPeriod] = useState(
    Itask.features.period || { min: 70, max: 90 }
  );
  const [rulesList, setRulesList] = useState(Itask.features.rules || []);
  const [successMessage, setSuccessMessage] = useState(false);
  const [leagueOptions, setLeagueOptions] = useState({
    maleLeagues: Itask.features.maleLeagues || false,
    femaleLeagues: Itask.features.femaleLeagues || false,
    youthLeagues: Itask.features.youthLeagues || false,
  });
  const [leaguesFromOptions, setLeaguesFromOptions] = useState(() => {
    let leagues = [];
    if (Itask.features.maleLeagues) {
      leagues = [...selectMaleProLeagues(scorebing_leagues)];
    }
    if (Itask.features.femaleLeagues) {
      leagues = [...leagues, ...selectFemaleLeagues(scorebing_leagues)];
    }
    if (Itask.features.youthLeagues) {
      leagues = [...leagues, ...selectYouthLeagues(scorebing_leagues)];
    }
    return leagues;
  });
  const [shouldMonitorOdds, setShouldMonitorOdds] = useState(
    Itask.oddFilter ? !!Itask.oddFilter.market : false
  );
  const [oddFilter, setOddFilter] = useState(() => {
    if (!Itask.oddFilter) {
      return {
        market: "goals",
        type: "over",
        line: null,
        minOdd: "",
      };
    }

    return {
      market: Itask.oddFilter.market || "goals",
      type: Itask.oddFilter.type || "over",
      line: Itask.oddFilter.line || null,
      minOdd: Itask.oddFilter.minOdd || "",
    };
  });
  const [isValidMinOdd, setIsValidMinOdd] = useState(true);
  const [isYouTubeModalOpen, setIsYouTubeModalOpen] = useState(false);
  const [isYouTubePlayerReady, setIsYouTubePlayerReady] = useState(false);

  const isLoadingPlan = plan.plan === null;
  const isActive = plan.status === "active";

  const shouldEnableSubmit = () => {
    if (!shouldMonitorOdds) {
      return true;
    }

    return oddFilter.line != null && Boolean(oddFilter.minOdd);
  };

  const lineToSend = () => {
    if (!oddFilter.line) {
      return null;
    }

    if (typeof oddFilter.line === "object") {
      return oddFilter.line.value;
    }

    return oddFilter.line;
  };

  const selectLineValue = () => {
    if (typeof oddFilter.line !== "object") {
      return selectLineByMarket(oddFilter.market).options.find(
        (o) => o.value === Number(oddFilter.line)
      );
    }

    return oddFilter.line;
  };

  const oddFilterToSend = shouldMonitorOdds
    ? {
        market: oddFilter.market,
        type: oddFilter.type,
        line: lineToSend(),
        minOdd: oddFilter.minOdd ? Number(oddFilter.minOdd) : 0,
      }
    : {};

  const hasLoadedLeagues = scorebing_leagues && scorebing_leagues.length > 0;

  const selectValue = useMemo(
    () =>
      removeLeaguesFromPreSelectedOptions({
        leaguesFromDatabase: task.leagues,
        preSelectedLeages: leaguesFromOptions,
      }),
    [task.leagues, leaguesFromOptions]
  );

  const handleFav = (e) => {
    setTask({ ...task, fav: e.target.name });
  };

  const handleLeagueOptions = (e) => {
    e.persist();

    const targetName = e.target.name;
    const isCurrentlyChecked = leagueOptions[targetName] === true;
    if (isCurrentlyChecked) {
      const leagueSelector = {
        maleLeagues: selectMaleProLeagues,
        femaleLeagues: selectFemaleLeagues,
        youthLeagues: selectYouthLeagues,
      };

      setTask((task) => ({
        ...task,
        leagues: removePreSelectedLeaguesFromDatabase({
          preSelectedLeages: leagueSelector[targetName](scorebing_leagues),
          leaguesFromDatabase: task.leagues,
        }),
      }));
    }

    setLeagueOptions((options) => ({
      ...options,
      [targetName]: e.target.checked,
    }));
  };

  useLayoutEffect(() => {
    let newState = [];
    if (leagueOptions.maleLeagues) {
      newState = [...newState, ...selectMaleProLeagues(scorebing_leagues)];
    }
    if (leagueOptions.femaleLeagues) {
      newState = [...newState, ...selectFemaleLeagues(scorebing_leagues)];
    }
    if (leagueOptions.youthLeagues) {
      newState = [...newState, ...selectYouthLeagues(scorebing_leagues)];
    }
    setLeaguesFromOptions(newState);
  }, [leagueOptions, scorebing_leagues]);

  const leaguesToWatch = () => {
    let leagues = task.leagues ? [...task.leagues] : [];
    if (leagueOptions.maleLeagues) {
      leagues = [...leagues, ...selectMaleProLeagues(scorebing_leagues)];
    }
    if (leagueOptions.femaleLeagues) {
      leagues = [...leagues, ...selectFemaleLeagues(scorebing_leagues)];
    }
    if (leagueOptions.youthLeagues) {
      leagues = [...leagues, ...selectYouthLeagues(scorebing_leagues)];
    }
    return deduplicateLeagues(leagues);
  };

  const handleDrawChange = (e) => {
    setTask({ ...task, isDraw: e.target.checked });
  };

  const handleNotDrawChange = (e) => {
    setTask({ ...task, notDraw: e.target.checked });
  };

  const handleOnlyWithCornersMarketChange = (e) => {
    setTask({ ...task, isOnlyWithCornersMarket: e.target.checked });
  };

  const deleteRule = (e, index) => {
    setRulesList(rulesList.filter((_, index2) => index2 !== index));
    setNewRule(false);
  };

  const onAddNewRule = (rule) => {
    rule.key = Math.random();
    const newRulesList = [...rulesList, rule];
    setRulesList(newRulesList);
    setNewRule(false);
  };

  const deleteBot = () => {
    deleteCustomBot();
    history.push("/");
  };

  const saveTask = async (event) => {
    event.preventDefault();

    if (id) {
      updateCustomBot(
        {
          description: task.description,
          rules: rulesList,
          period: period,
          fav: task.fav,
          isDraw: task.isDraw,
          notDraw: task.notDraw,
          isOnlyWithCornersMarket: task.isOnlyWithCornersMarket,
          leagues: leaguesToWatch(),
          maleLeagues: leagueOptions.maleLeagues,
          femaleLeagues: leagueOptions.femaleLeagues,
          youthLeagues: leagueOptions.youthLeagues,
        },
        oddFilterToSend,
        id
      );
    } else {
      createCustomBot(
        {
          description: task.description,
          rules: rulesList,
          period: period,
          fav: task.fav,
          isDraw: task.isDraw,
          notDraw: task.notDraw,
          isOnlyWithCornersMarket: task.isOnlyWithCornersMarket,
          leagues: leaguesToWatch(),
          maleLeagues: leagueOptions.maleLeagues,
          femaleLeagues: leagueOptions.femaleLeagues,
          youthLeagues: leagueOptions.youthLeagues,
        },
        oddFilterToSend
      );
    }
    setSuccessMessage(true);
  };

  const redirectToDashboard = () => {
    history.push("/");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (id && !Itask.features) {
    return <div></div>;
  }

  if (!isLoadingPlan && !isActive && window.location.pathname.includes("new")) {
    return <Redirect to="/checkout" />;
  }

  return (
    <div className="custom-bot-container">
      {successMessage ? (
        <SweetAlert
          show={true}
          title="Robô salvo"
          text="Em breve você receberá alertas dele"
          confirmButtonClass="btn btn-primary btn-large"
          buttonsStyling={false}
          type="success"
          onConfirm={redirectToDashboard}
        />
      ) : null}
      <h2>Configure um robo de acordo com as suas regras:</h2>

      <div className="row" style={{ maxWidth: "800px" }}>
        <div
          className="dashboard-cta-container card"
          style={{
            backgroundColor: "rgb(244, 234, 255, 0.5)",
            border: "1px dashed #4e016d",
            marginTop: "24px",
          }}
        >
          <p style={{ margin: 0 }}>
            Criamos um tutorial <strong>especial para você!</strong>
          </p>
          <p style={{ margin: 0 }}>
            Assista para aproveitar o CUSTOM BOT da melhor forma!
          </p>
          <button
            type="button"
            onClick={() => setIsYouTubeModalOpen(true)}
            className="btn btn-large btn-primary"
          >
            Ver tutorial
          </button>
        </div>

        <Modal
          isOpen={isYouTubeModalOpen}
          onAfterOpen={() => (document.body.style.overflow = "hidden")}
          onAfterClose={() => (document.body.style.overflow = "unset")}
          onRequestClose={() => {
            setIsYouTubeModalOpen(false);
            setIsYouTubePlayerReady(false);
          }}
          ariaHideApp={false}
          style={{
            content: {
              inset: "60px 20px 60px 20px",
              borderRadius: "12px",
              boxShadow: "0 25px 50px -12px rgb(0 0 0 / 0.25)",
            },
          }}
        >
          <div className="youtube-modal-content-container">
            <button
              type="button"
              className="btn-close-youtube-modal"
              onClick={() => {
                setIsYouTubeModalOpen(false);
                setIsYouTubePlayerReady(false);
              }}
            >
              X
            </button>
            {isYouTubePlayerReady ? null : (
              <span className="loader--youtube-player"></span>
            )}
            <YouTube
              videoId="3sK6ZmGQ1rE"
              opts={{
                playerVars: {
                  autoplay: 1,
                },
              }}
              className="youtube-player"
              onReady={() => setIsYouTubePlayerReady(true)}
            />
          </div>
        </Modal>

        <div className="card-custom-bot">
          <div className="row">
            <div className="col-xs-12">
              <div className="form-group-input">
                <label>De um nome para seu robô:</label>
                <input
                  className="effect-1 custom-bot-name-input"
                  type="text"
                  placeholder="Robô customizado"
                  value={task.description}
                  onChange={(e) => {
                    setTask({ ...task, description: e.target.value });
                  }}
                />
                <span className="focus-border" />
              </div>
            </div>
          </div>

          <p className="you-will-receive-alert">
            Você será alertado quando um jogo encaixar nas regras que você
            configurar.
          </p>

          <div className="row">
            <div className="col-xs-8 col-sm-8">
              <button
                className="btn btn-outline"
                onClick={() => setNewRule(true)}
              >
                + Criar regra
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              {newRule && (
                <NewRuleForm addNewRule={(rule) => onAddNewRule(rule)} />
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 vertical-offset-3">
              <h4>Regras adicionadas ao robô: </h4>

              <BotCustomRuleItem
                rulesList={rulesList}
                deleteRule={deleteRule}
              />
            </div>
          </div>
          <div className="row vertical-offset-5">
            <div className="col-xs-12">
              <div className="form-group">
                <h4>Período do jogo: </h4>
                <InputRange
                  value={period}
                  id="period"
                  onChange={(value) => setPeriod(value)}
                  formatLabel={(value) => `${value} mins`}
                  maxValue={90}
                  minValue={0}
                  draggableTrack
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="form-group">
                <label>Qual situação o jogo deve estar?</label>
                <RadioToolbar column="radio-toolbar-column">
                  <input
                    type="radio"
                    id="LOSING"
                    name="LOSING"
                    value="LOSING"
                    onChange={handleFav}
                    checked={task.fav === "LOSING"}
                  />
                  <label htmlFor="LOSING">Favorito perdendo</label>
                  <input
                    type="radio"
                    id="EVEN"
                    name="EVEN"
                    value="EVEN"
                    onChange={handleFav}
                    checked={task.fav === "EVEN"}
                  />
                  <label htmlFor="EVEN">Favorito perdendo ou empatando</label>
                  <input
                    type="radio"
                    id="BOTH"
                    name="BOTH"
                    value="BOTH"
                    onChange={handleFav}
                    checked={task.fav === "BOTH"}
                  />
                  <label htmlFor="BOTH">Tanto Faz</label>
                </RadioToolbar>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>
              Ligas (Deixe em branco para selecionar todas as ligas)
            </label>
            {hasLoadedLeagues ? (
              <>
                <div className="checkbox-group-container">
                  <Checkbox
                    labelText="Futebol Masculino 🏃‍♂️"
                    id="maleLeagues"
                    name="maleLeagues"
                    checked={leagueOptions.maleLeagues}
                    onChange={handleLeagueOptions}
                  />
                  <Checkbox
                    labelText="Futebol Feminino 🏃‍♀️"
                    id="femaleLeagues"
                    name="femaleLeagues"
                    checked={leagueOptions.femaleLeagues}
                    onChange={handleLeagueOptions}
                  />
                  <Checkbox
                    labelText="Futebol de Base 👶"
                    id="youthLeagues"
                    name="youthLeagues"
                    checked={leagueOptions.youthLeagues}
                    onChange={handleLeagueOptions}
                  />
                </div>
                <Select
                  components={makeAnimated()}
                  placeholder="Selecione campeonatos específicos"
                  isMulti
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      padding: "8px",
                    }),
                    valueContainer: (baseStyles) => ({
                      ...baseStyles,
                      padding: "8px",
                    }),
                  }}
                  options={removeLeaguesFromPreSelectedOptions({
                    leaguesFromDatabase: scorebing_leagues,
                    preSelectedLeages: leaguesFromOptions,
                  })}
                  value={selectValue}
                  onChange={(value) =>
                    setTask((task) => ({ ...task, leagues: value }))
                  }
                />
                <small>Deixe em branco para selecionar todas as ligas</small>

                {leaguesToWatch().length > 0 ? (
                  <div style={{ marginTop: "8px" }}>
                    <small>
                      O seu robô vai olhar para{" "}
                      <strong className="leagues-to-watch-length">
                        {leaguesToWatch().length}
                      </strong>{" "}
                      ligas.
                    </small>
                  </div>
                ) : null}
              </>
            ) : (
              <small>Carregando ligas...</small>
            )}
          </div>
          <div className="form-group">
            <label>Monitoramento de odds</label>
            <RadioToolbar column="radio-toolbar-row">
              <input
                type="radio"
                id="not-monitor-odds"
                name="not-monitor-odds"
                value="not-monitor-odds"
                onChange={() => setShouldMonitorOdds(false)}
                checked={!shouldMonitorOdds}
              />
              <label htmlFor="not-monitor-odds">Não monitorar odds</label>
              <input
                type="radio"
                id="monitor-odds"
                name="monitor-odds"
                value="monitor-odds"
                onChange={() => setShouldMonitorOdds(true)}
                checked={shouldMonitorOdds}
              />
              <label htmlFor="monitor-odds">Monitorar odds</label>
            </RadioToolbar>
          </div>
          {shouldMonitorOdds ? (
            <div className="form-group form-odds-monitor">
              <label className="market-label">Mercado (somente FT)</label>
              <RadioToolbar column="radio-toolbar-row">
                <input
                  type="radio"
                  id="goals-mkt"
                  name="goals-mkt"
                  value="goals-mkt"
                  onChange={() => {
                    setOddFilter((s) => ({
                      ...s,
                      market: "goals",
                      line: null,
                    }));
                  }}
                  checked={oddFilter.market === "goals"}
                />
                <label htmlFor="goals-mkt">Gols</label>
                <input
                  type="radio"
                  id="corners-mkt"
                  name="corners-mkt"
                  value="corners-mkt"
                  onChange={() => {
                    setOddFilter((s) => ({
                      ...s,
                      market: "corners",
                      line: null,
                    }));
                  }}
                  checked={oddFilter.market === "corners"}
                />
                <label htmlFor="corners-mkt">Escanteios</label>
              </RadioToolbar>

              <label>Tipo</label>
              <RadioToolbar column="radio-toolbar-row">
                <input
                  type="radio"
                  id="over"
                  name="over"
                  value="over"
                  onChange={() => {
                    setOddFilter((s) => ({ ...s, type: "over" }));
                  }}
                  checked={oddFilter.type === "over"}
                />
                <label htmlFor="over">Over</label>
                <input
                  type="radio"
                  id="under"
                  name="under"
                  value="under"
                  onChange={() => {
                    setOddFilter((s) => ({ ...s, type: "under" }));
                  }}
                  checked={oddFilter.type === "under"}
                />
                <label htmlFor="under">Under</label>
              </RadioToolbar>

              <label>
                Linhas asiáticas <strong className="required-signal">*</strong>
              </label>
              <Select
                components={makeAnimated()}
                placeholder={selectLineByMarket(oddFilter.market).placeholder}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    padding: "8px",
                  }),
                  valueContainer: (baseStyles) => ({
                    ...baseStyles,
                    padding: "8px",
                  }),
                }}
                options={selectLineByMarket(oddFilter.market).options}
                value={selectLineValue()}
                onChange={(value) => {
                  setOddFilter((s) => ({ ...s, line: value }));
                }}
              />

              <label>
                Odd mínima <strong className="required-signal">*</strong>
              </label>
              <input
                className="text-input"
                type="text"
                placeholder="Exemplo: 1.7"
                value={oddFilter.minOdd || ""}
                required
                onChange={(e) => {
                  e.persist();

                  const mask = /^[0-9]+(\.[0-9]+)?$/;
                  const inputValue = e.target.value;
                  if (inputValue) {
                    setIsValidMinOdd(mask.test(inputValue));
                  }

                  setOddFilter((s) => ({
                    ...s,
                    minOdd: inputValue,
                  }));
                }}
              />
              {isValidMinOdd ? null : (
                <div className="input-error">
                  Odd mínima inválida. Exemplo: 1.7
                </div>
              )}

              <div className="input-disclaimer">
                <strong className="required-signal">*</strong> Os campos com
                asterisco são obrigatórios
              </div>
            </div>
          ) : null}
          <div className="form-group">
            <div className="checkbox-container">
              <input
                type="checkbox"
                name="isDraw"
                id="isDraw"
                checked={task.isDraw}
                onChange={handleDrawChange}
                disabled={task.notDraw}
              />
              <label htmlFor="isDraw">Somente jogos empatados</label>
            </div>

            <div className="form-group">
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="notDraw"
                  id="notDraw"
                  checked={task.notDraw}
                  onChange={handleNotDrawChange}
                  disabled={task.isDraw}
                />
                <label htmlFor="notDraw">Somente jogos não empatados</label>
              </div>

              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="onlyWithCornersMarket"
                  id="onlyWithCornersMarket"
                  checked={task.isOnlyWithCornersMarket}
                  onChange={handleOnlyWithCornersMarketChange}
                />
                <label htmlFor="onlyWithCornersMarket">
                  Somente jogos com mercado de escanteios disponível
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-8 col-sm-3">
              <button
                onClick={saveTask}
                className="btn btn-primary"
                disabled={!shouldEnableSubmit()}
              >
                Salvar
              </button>
            </div>
            {id && (
              <div className="col-xs-4 col-sm-3">
                <button onClick={deleteBot} className="btn btn-outline">
                  Excluir
                </button>
              </div>
            )}
          </div>
          {!shouldEnableSubmit() ? (
            <div className="submit-disclaimer">
              Preencha os campos obrigatórios
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  let idProject = ownProps.match.params.id;

  let task = {};
  if (state.firestore.data.tasks) {
    task = state.firestore.data.tasks[idProject];
  }

  let scorebing_leagues = {};
  if (state.firestore.data.scorebing_leagues) {
    scorebing_leagues = state.firestore.data.scorebing_leagues;
  }
  const result = Object.keys(scorebing_leagues).map(function (key) {
    return { value: key, label: scorebing_leagues[key].league_name };
  });

  return {
    auth: state.firebase.auth,
    Itask: task,
    plan: state.plan,
    id: ownProps.match.params.id,
    scorebing_leagues: result,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  let idProject = ownProps.match.params.id;
  return {
    createCustomBot: (customBot, oddFilter) =>
      dispatch(createCustomBot(customBot, oddFilter)),
    deleteCustomBot: (customBot) => dispatch(deleteBot(idProject)),
    updateCustomBot: (customBot, oddFilter, id) =>
      dispatch(updateCustomBot(customBot, oddFilter, id)),
  };
};
export default compose(
  firestoreConnect((props) => {
    let idProject = props.match.params.id;

    if (!idProject) {
      return [
        {
          collection: "scorebing_leagues",
        },
      ];
    } else {
      return [
        {
          collection: "tasks",
          doc: idProject,
        },
        {
          collection: "scorebing_leagues",
        },
      ];
    }
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(BotCustom);
