import firebase from "firebase";

export const deleteChat = (userId) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_DELETE_CHAT" });

    try {
      const authToken = await firebase.auth().currentUser.getIdToken(true);

      const response = await fetch(`/communicators/${userId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken ? `${authToken}` : undefined,
        },
      });

      if (!response.ok) {
        dispatch({ type: "ERROR_DELETE_CHAT" });
        return;
      }

      dispatch({ type: "SUCCESS_DELETE_CHAT" });
    } catch (error) {
      dispatch({ type: "ERROR_DELETE_CHAT" });
    }
  };
};
