import React from "react";
import { descriptionByType } from "../constants/botProperties";

const BotCardListItemName = ({ botType, botDescription }) => {
  const descriptionConfig = descriptionByType[botType];
  const hasDescription = !!descriptionConfig && descriptionConfig.description;
  const botDescriptionType = hasDescription
    ? descriptionConfig.description
    : "";

  const botLabel = botDescription || botDescriptionType;

  return <span className="card-list-item-name">{botLabel}</span>;
};

export default BotCardListItemName;
