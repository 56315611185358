import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteChat as deleteChatAction } from "../store/actions/communicatorsActions";
import { connect } from "react-redux";
import SweetAlert from "sweetalert2-react";
import { useHistory } from "react-router-dom";

const Spinner = () => <span className="loader"></span>;

const ChatsCardList = ({ chats, communicatorsState, deleteChat, auth }) => {
  const history = useHistory();

  const { status } = communicatorsState;
  const isLoading = status === "loading";

  const redirectToDashboard = () => {
    history.push("/");
  };

  return (
    <div className="card">
      {status === "error" ? (
        <SweetAlert
          show={true}
          title="Falha ao remover conexão"
          confirmButtonClass="btn btn-primary btn-large"
          buttonsStyling={false}
          type="warning"
          onConfirm={redirectToDashboard}
        />
      ) : null}
      <div className="chats-list-title-container">
        <span>
          Seus Chats
          <FontAwesomeIcon icon="paper-plane" />
        </span>
        {chats ? (
          <span>
            <button
              className={`btn-delete-chat ${
                isLoading ? "btn-delete-chat--loading" : ""
              }`}
              onClick={() => deleteChat(auth.uid)}
            >
              Desconectar
              {isLoading ? <Spinner /> : null}
            </button>
          </span>
        ) : null}
      </div>
      <ul className="card-list">
        {chats
          ? chats.map((chat) => {
              return (
                <li className="card-list-item" key={chat.chatId}>
                  <span className="card-list-item-icon">
                    <FontAwesomeIcon icon="paper-plane" />
                  </span>
                  Conectado ao {chat.type}
                </li>
              );
            })
          : null}
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    communicatorsState: state.communicators,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteChat: (userId) => dispatch(deleteChatAction(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatsCardList);
