import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import BotCardListItemName from "./BotCardListItemName";
import Switch from "react-switch";

class BotsCardList extends Component {
  handleChange = (bot) => {
    let botUpdated = Object.assign({}, bot);
    if (bot.status === "ACTIVE") {
      botUpdated.status = "INACTIVE";
    } else {
      botUpdated.status = "ACTIVE";
    }
    this.props.onUpdateBot(botUpdated);
  };

  render() {
    let moreButton = "";
    let newButton = "";

    const { bots } = this.props;

    if (bots.length > 0) {
      newButton = (
        <div className="card-title-button">
          <Link to="/new-bot-type">
            <button className="btn btn-outline">Novo robô</button>
          </Link>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-title">
          Seus Bots
          <FontAwesomeIcon icon="robot" />
          {newButton}
        </div>
        <ul className="card-list">
          {bots &&
            bots.map((bot) => {
              if (bot.type == "CORNERS") {
                return (
                  <Link to={"/bot/" + bot.id} key={bot.id}>
                    <li className="card-list-item">
                      <span className="card-list-item-icon">
                        <FontAwesomeIcon icon="flag" />
                      </span>
                      <BotCardListItemName
                        botType={bot.type}
                        botDescription={bot.features.description}
                      />

                      <span>
                        <Switch
                          onColor="#7AC70C"
                          onChange={() => {
                            this.handleChange(bot);
                          }}
                          checked={bot.status === "ACTIVE"}
                        />
                      </span>
                    </li>
                  </Link>
                );
              }

              if (bot.type === "OPEN_MATCH") {
                return (
                  <Link to={"/bot-open/" + bot.id} key={bot.id}>
                    <li className="card-list-item">
                      <span className="card-list-item-icon">
                        <FontAwesomeIcon icon="lock-open" />
                      </span>
                      <BotCardListItemName botType={bot.type} />
                      <span>
                        <Switch
                          onColor="#7AC70C"
                          onChange={() => {
                            this.handleChange(bot);
                          }}
                          checked={bot.status === "ACTIVE"}
                        />
                      </span>
                    </li>
                  </Link>
                );
              }

              if (bot.type === "OPEN_MATCH_VOLLEY") {
                return (
                  <Link to={"/open-volley-bot/" + bot.id} key={bot.id}>
                    <li className="card-list-item">
                      <span className="card-list-item-icon">
                        <FontAwesomeIcon icon="lock-open" />
                      </span>
                      <BotCardListItemName botType={bot.type} />
                      <span>
                        <Switch
                          onColor="#7AC70C"
                          onChange={() => {
                            this.handleChange(bot);
                          }}
                          checked={bot.status === "ACTIVE"}
                        />
                      </span>
                    </li>
                  </Link>
                );
              }

              if (bot.type === "OPEN_MATCHS_BETFAIR") {
                return (
                  <Link to={"/open-betfair-bot/" + bot.id} key={bot.id}>
                    <li className="card-list-item">
                      <span className="card-list-item-icon">
                        <FontAwesomeIcon icon="lock-open" />
                      </span>
                      <BotCardListItemName botType={bot.type} />
                      <span>
                        <Switch
                          onColor="#7AC70C"
                          onChange={() => {
                            this.handleChange(bot);
                          }}
                          checked={bot.status === "ACTIVE"}
                        />
                      </span>
                    </li>
                  </Link>
                );
              }

              if (bot.type === "OPEN_MATCHS_FIFA") {
                return (
                  <Link to={"/open-fifa-bot/" + bot.id} key={bot.id}>
                    <li className="card-list-item">
                      <span className="card-list-item-icon">
                        <FontAwesomeIcon icon="lock-open" />
                      </span>
                      <BotCardListItemName botType={bot.type} />
                      <span>
                        <Switch
                          onColor="#7AC70C"
                          onChange={() => {
                            this.handleChange(bot);
                          }}
                          checked={bot.status === "ACTIVE"}
                        />
                      </span>
                    </li>
                  </Link>
                );
              }

              if (bot.type === "OPEN_MATCHS_MOLLY") {
                return (
                  <Link to={"/open-molly-bot/" + bot.id} key={bot.id}>
                    <li className="card-list-item">
                      <span className="card-list-item-icon">
                        <FontAwesomeIcon icon="lock-open" />
                      </span>
                      <BotCardListItemName botType={bot.type} />
                      <span>
                        <Switch
                          onColor="#7AC70C"
                          onChange={() => {
                            this.handleChange(bot);
                          }}
                          checked={bot.status === "ACTIVE"}
                        />
                      </span>
                    </li>
                  </Link>
                );
              }

              if (bot.type === "OVER_GOALS") {
                return (
                  <Link to={"/over-goals-bot/" + bot.id} key={bot.id}>
                    <li className="card-list-item">
                      <span className="card-list-item-icon">
                        <FontAwesomeIcon icon="futbol" />
                      </span>
                      <BotCardListItemName
                        botType={bot.type}
                        botDescription={bot.features.description}
                      />
                      <span>
                        <Switch
                          onColor="#7AC70C"
                          onChange={() => {
                            this.handleChange(bot);
                          }}
                          checked={bot.status === "ACTIVE"}
                        />
                      </span>
                    </li>
                  </Link>
                );
              }

              if (bot.type === "UNDER_GOALS") {
                return (
                  <Link to={"/under-goals-bot/" + bot.id} key={bot.id}>
                    <li className="card-list-item">
                      <span className="card-list-item-icon">
                        <FontAwesomeIcon icon="futbol" />
                      </span>
                      <BotCardListItemName
                        botType={bot.type}
                        botDescription={bot.features.description}
                      />
                      <span>
                        <Switch
                          onColor="#7AC70C"
                          onChange={() => {
                            this.handleChange(bot);
                          }}
                          checked={bot.status === "ACTIVE"}
                        />
                      </span>
                    </li>
                  </Link>
                );
              }

              if (bot.type === "CUSTOM_BOT") {
                return (
                  <Link to={"/custom-bot/" + bot.id} key={bot.id}>
                    <li className="card-list-item">
                      <span className="card-list-item-icon">
                        <FontAwesomeIcon icon="futbol" />
                      </span>
                      <BotCardListItemName
                        botType={bot.type}
                        botDescription={bot.features.description}
                      />
                      <span>
                        <Switch
                          onColor="#7AC70C"
                          onChange={() => {
                            this.handleChange(bot);
                          }}
                          checked={bot.status === "ACTIVE"}
                        />
                      </span>
                    </li>
                  </Link>
                );
              }
            })}
        </ul>
        {moreButton}
      </div>
    );
  }
}

export default BotsCardList;
