import React, { Component } from "react";
import { connect } from "react-redux";
import { signIn } from "../store/actions/authActions";
import { Redirect } from "react-router-dom";
import logo from "../assets/BetBots-purple.svg";
import googleLogo from "../assets/google-logo.png";
import { Mixpanel } from "../constants/mixpanel";

class SignIn extends Component {
  handleSubmit = e => {
    Mixpanel.track("Tentou logar");
    e.preventDefault();
    this.props.signIn();
  };
  render() {
    Mixpanel.track("Visitou página de login");
    const { authError, auth } = this.props;
    if (auth.uid) return <Redirect to="/dashboard" />;
    return (
      <div className="container">
        <div className="row middle-xs center-xs">
          <div className="col-xs-12">
            <img src={logo} className="logo vertical-offset-5" alt="Logo" />
          </div>
          <div className="col-xs-12 col-lg-4 vertical-offset-4">
            <button
              onClick={this.handleSubmit}
              className="btn btn-primary btn-large btn-icon"
            >
              <img src={googleLogo} className="google-icon" />
              Conectar com Google
            </button>
          </div>

          <div className="">{authError ? <p>{authError}</p> : null}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: () => dispatch(signIn())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
