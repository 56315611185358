import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const BotsCardBlankslate = () => {
  return (
    <div className="card">
      <div className="card-title">
        Bots
        <FontAwesomeIcon icon="robot" />
      </div>
      <div className="row vertical-offset-3 center-xs">
        <div className="col-xs-12">
          <p>
            Você não tem nenhum robô cadastrado para analisar jogos por você.
          </p>
        </div>
        <div
          className="col-xs-12 col-lg-6 vertical-offset-3"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Link to="/new-bot-type">
            <button className="btn btn-primary">Criar primeiro robô</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BotsCardBlankslate;
