import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createBotOpenVolley,
  updateBotOpenVolley
} from "../store/actions/taskActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import InputRange from "react-input-range";
import RadioToolbar from "./RadioToolbar";
import "react-input-range/lib/css/index.css";
import SweetAlert from "sweetalert2-react";
import { Redirect } from "react-router";
import { Mixpanel } from "../constants/mixpanel";

class BotOpenNewVolley extends Component {
  state = {
    restList: false,
    brazilList: false,
    meetingsList: false,
    redirect: false,
    successMessage: false,
    lists: []
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };
  submitForm = event => {
    event.preventDefault();
    Mixpanel.track("Salvou robo de abertura de vôlei com sucesso");
    const lists = [];
    if (this.state.brazilList) {
      lists.push("Lista do Brasil");
    }

    if (this.state.restList) {
      lists.push("Resto do Mundo");
    }

    if (this.state.meetingsList) {
      lists.push("Encontros");
    }

    this.setState(
      {
        lists,
        successMessage: true
      },
      () => {
        console.log("salvando", this.state);
        if (this.props.match.params.id) {
          this.props.updateBotOpen(this.state, this.props.match.params.id);
        } else {
          this.props.createBotOpen(this.state);
        }
      }
    );
  };
  redirectToDashboard = () => {
    this.setState({
      redirect: true
    });
  };
  componentWillReceiveProps(props) {
    if (props.task && props.task.features) {
      let features = {};
      features.restList = props.task.features.restList || false;
      features.brazilList = props.task.features.brazilList || false;
      features.meetingsList = props.task.features.meetingsList || false;

      this.setState({
        restList: features.restList,
        brazilList: features.brazilList,
        meetingsList: features.meetingsList
      });
    }
  }
  render() {
    if (
      this.props.plan &&
      this.props.plan.status != "active" &&
      this.props.match.path === "/new-open-volley-bot"
    ) {
      return <Redirect to="/checkout" />;
    }

    Mixpanel.track("Visitou novo robo de aberturas de vôlei");
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    if (this.state.successMessage) {
      Mixpanel.track("Criou/atualizou robo de aberturas de vôlei com sucesso");
      return (
        <SweetAlert
          show={true}
          title="Robô salvo"
          text="Em breve você receberá alertas dele"
          confirmButtonClass="btn btn-primary btn-large"
          buttonsStyling={false}
          type="success"
          onConfirm={this.redirectToDashboard}
        />
      );
    }
    return (
      <div>
        <h2>Configure o robô de aberturas de volêi:</h2>
        <div className="row">
          <div className="card">
            <p>
              Você será alertado quando um jogo novo aparecer nas
              listas/campeonatos selecionados aqui
            </p>
            <form>
              <div className="form-group">
                <label>Listas principais</label>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="brazilList"
                    id="brazilList"
                    checked={this.state.brazilList}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="brazilList">Lista do Brasil</label>
                </div>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="meetingsList"
                    id="meetingsList"
                    checked={this.state.meetingsList}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="meetingsList">Encontros</label>
                </div>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="restList"
                    id="restList"
                    checked={this.state.restList}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="restList">Resto do Mundo</label>
                </div>
              </div>

              <div className="information">
                Para jogos que não tem nessas listas entre em contato pelo chat
              </div>

              <button
                onClick={this.submitForm}
                className="btn btn-primary vertical-offset-4"
              >
                Salvar
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let idProject = ownProps.match.params.id;
  let task = {};
  console.log(state.firestore);
  if (state.firestore.data.tasks) {
    task = state.firestore.data.tasks[idProject];
  }
  return {
    auth: state.firebase.auth,
    task: task,
    plan: state.plan
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  let idProject = ownProps.match.params.id;
  return {
    createBotOpen: botCorner => dispatch(createBotOpenVolley(botCorner)),
    updateBotOpen: botCorner =>
      dispatch(updateBotOpenVolley(botCorner, idProject))
  };
};
export default compose(
  firestoreConnect(props => {
    let idProject = props.match.params.id;
    console.log("idddddddd" + idProject);

    if (!idProject) {
      return [];
    } else {
      return [
        {
          collection: "tasks",
          doc: idProject
        }
      ];
    }
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(BotOpenNewVolley);
