import React from "react";
import { connect } from "react-redux";
import logo from "../assets/BetBots-white.svg";
import { signOut, setSource } from "../store/actions/authActions";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FreshChat from "react-freshchat";
import { getPlanStatus as getPlanStatusAction } from "../store/actions/planAction";

function Navbar(props) {
  const { location, auth, plan } = props;
  const isLoadingPlan = plan.plan === null;
  const vipLabel = plan.status === "active" ? "Membro VIP" : "Vire VIP Agora";
  const vipLink = plan.status === "active" || isLoadingPlan ? "/" : "/checkout";
  const emailLabel = auth.email || "";

  const handleSignOut = (e) => {
    e.preventDefault();
    props.signOut();
  };

  React.useEffect(() => {
    if (auth.uid) {
      props.getPlanStatus(auth.uid);
    }
  }, []);

  if (location.pathname.match("/signin")) {
    return null;
  }

  if (auth.uid) {
    let sourceCookie = false;
    const match = document.cookie.match(
      new RegExp("(^| )" + "bbots-source" + "=([^;]+)")
    );
    if (match) {
      sourceCookie = match[2];
    }

    if (sourceCookie) {
      props.setSource(auth.uid, sourceCookie);
    }

    return (
      <>
        <div className="navbar">
          <div className="container">
            <div className="row center-xs">
              <div className="col-xs-4  col-lg-2  left">
                <Link to="/">
                  <img src={logo} className="logo" alt="Logo" />
                </Link>
              </div>

              <ul className="col-xs-8 col-lg-4 col-lg-offset-6 right navbar-items">
                <li>
                  <Link to={vipLink}>
                    <button
                      className={`btn btn-inverted btn-small ${
                        isLoadingPlan ? "loading-plan-button" : ""
                      }`}
                    >
                      {isLoadingPlan ? "..." : vipLabel}
                    </button>
                  </Link>
                </li>
                <li>
                  <a href="#" className="navbar-item" onClick={handleSignOut}>
                    <FontAwesomeIcon icon="sign-out-alt" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <FreshChat
            token="d22d4388-aba5-4dad-835d-26beddf5aed9"
            email={auth.email}
            onInit={(widget) => {
              widget.user.setProperties({
                email: auth.email,
                first_name: auth.displayName,
                uid: auth.uid,
              });
            }}
          />
        </div>
        {emailLabel ? (
          <div className="container logged-with-email-container">
            <span className="logged-with-email">
              Logado com: <strong>{emailLabel}</strong>
            </span>
          </div>
        ) : null}
      </>
    );
  }

  return <Redirect to="/signin" />;
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    plan: state.plan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    setSource: (uid, source) => dispatch(setSource(uid, source)),
    getPlanStatus: (uid) => dispatch(getPlanStatusAction(uid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
