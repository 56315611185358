const initState = {
  status: "idle",
};

const communicatorsReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOADING_DELETE_CHAT":
      return { status: "loading" };
    case "SUCCESS_DELETE_CHAT":
      return { status: "success" };
    case "ERROR_DELETE_CHAT":
      return { status: "error" };
    default:
      return state;
  }
};

export default communicatorsReducer;
