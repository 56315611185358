import React from "react";

const CheckIcon = () => {
  return (
    <svg width="10" height="8" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="#fff"
        strokeWidth="2"
        fill="none"
        d="m1.276 3.066 2.756 2.756 5-5"
      />
    </svg>
  );
};

const Checkbox = ({ labelText, name, ...props }) => {
  return (
    <div className="checkbox-wrapper">
      <label className="checkbox-label" htmlFor={name}>
        <input
          type="checkbox"
          className="hidden-checkbox-input"
          name={name}
          {...props}
        />
        <div className="checkbox-icon-wrapper">
          <CheckIcon />
        </div>
        {labelText}
      </label>
    </div>
  );
};

export default Checkbox;
