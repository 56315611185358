import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { signOut } from "../store/actions/authActions";
import { updateBotStatus } from "../store/actions/taskActions";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link } from "react-router-dom";

import ChatsCard from "./ChatsCard";
import BotsCard from "./BotsCard";
import { Mixpanel } from "../constants/mixpanel";

const Dashboard = ({ auth, updateBotStatus, communicators, tasks, plan }) => {
  const [filteredTasks, setFilteredTasks] = useState();
  const isVip = plan.status === "active";
  const isLoadingPlan = plan.plan === null;

  useEffect(() => {
    if (tasks) {
      const newTasks = tasks.filter((task) => {
        const isTaskVisible = Boolean(task.invisible) === false;
        return isVip ? true : isTaskVisible;
      });

      setFilteredTasks(newTasks);
    }
  }, [tasks, plan.status]);

  React.useEffect(() => {
    Mixpanel.identify(auth.uid);
    Mixpanel.track("Visitou dashboard");
    Mixpanel.people.set({
      $name: auth.displayName || "Sem nome",
      $email: auth.email || "Sem email",
    });
  }, []);

  return (
    <div className="dashboard-container">
      <h2 className="vertical-offset-2">Dashboard</h2>
      <div className="row">
        <div className="col-lg-6 col-xs-12">
          {filteredTasks && (
            <BotsCard
              bots={filteredTasks}
              onUpdateBot={(bot) => {
                updateBotStatus(bot);
              }}
            />
          )}
        </div>
        <div className="col-lg-6 col-xs-12">
          <ChatsCard chats={communicators} id={auth.uid} />
        </div>
        {isVip || isLoadingPlan ? null : (
          <div className="col-lg-6 col-xs-12">
            <div className="dashboard-cta-container card card-filled">
              <p>
                Nossos VIPs têm acesso às melhores ferramentas do mercado de
                apostas!
              </p>
              <Link to="/checkout">
                <button className="btn btn-inverted btn-large">
                  Quero ser VIP Agora
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    plan: state.plan,
    auth: state.firebase.auth,
    communicators: state.firestore.ordered.new_communicators,
    tasks: state.firestore.ordered.tasks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    updateBotStatus: (bot) => dispatch(updateBotStatus(bot)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (props.auth.isEmpty) {
      return [];
    } else {
      return [
        {
          collection: "new_communicators",
          where: ["uid", "==", props.auth.uid],
        },
        {
          collection: "tasks",
          where: ["userId", "==", props.auth.uid],
        },
      ];
    }
  })
)(Dashboard);
