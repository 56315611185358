import moment from "moment";

const createLastRunData = (firestore, taskId, lastRun) => {
  console.log("CHAMOU CREATE LAST RUN DATA");

  const lastRunRef = firestore.collection("tasks_last_run");

  lastRunRef
    .add({
      lastRun,
      taskId,
    })
    .then(() => {
      console.log("create last run success");
    })
    .catch((err) => {
      console.log("create last run err", err);
    });
};

const updateLastRunData = (firestore, taskId, lastRun) => {
  console.log("CHAMOU UPDATE LAST RUN DATA");

  const lastRunRef = firestore.collection("tasks_last_run");

  lastRunRef
    .where("taskId", "==", taskId)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        return createLastRunData(firestore, taskId, lastRun);
      }

      const doc = snapshot.docs[0];

      doc.ref
        .update({
          lastRun,
        })
        .then(() => {
          console.log("update last run success");
        })
        .catch((error) => {
          console.log("update last run err", error);
        });
    });
};

export const createBotCorner = (botCorner, getPlan) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks");
    const userId = getState().firebase.auth.uid;
    const task = {
      features: botCorner,
      userId: userId,
      recurrencyTime: { number: 2, type: "minutes" },
      type: "CORNERS",
      lastRun: moment().unix(),
      createdAt: moment().unix(),
      status: "ACTIVE",
    };

    taskRef
      .add(task)
      .then((data) => {
        dispatch({ type: "CREATE_TASK_SUCCESS" });

        createLastRunData(firestore, data.id, task.lastRun);

        if (getPlan) {
          getPlan();
        }
      })
      .catch((err) => {
        dispatch({ type: "CREATE_TASK_ERROR" }, err);
      });
  };
};

export const updateBotCorner = (botCorner, id, getPlan) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks").doc(id);
    const userId = getState().firebase.auth.uid;
    const task = {
      features: botCorner,
      userId: userId,
      recurrencyTime: { number: 2, type: "minutes" },
      type: "CORNERS",
      lastRun: moment().unix(),
      updatedAt: moment().unix(),
      status: "ACTIVE",
    };

    taskRef
      .update(task)
      .then(() => {
        dispatch({ type: "UPDATE_TASK_SUCCESS" });

        updateLastRunData(firestore, id, task.lastRun);

        if (getPlan) {
          getPlan();
        }
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_TASK_ERROR" }, err);
      });
  };
};

export const createBotOpen = (botOpen) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks");
    const userId = getState().firebase.auth.uid;
    const task = {
      features: botOpen,
      userId: userId,
      recurrencyTime: { number: 1, type: "minutes" },
      type: "OPEN_MATCH",
      lastRun: moment().unix(),
      createdAt: moment().unix(),
      status: "ACTIVE",
    };

    taskRef
      .add(task)
      .then((data) => {
        dispatch({ type: "CREATE_TASK_SUCCESS" });

        createLastRunData(firestore, data.id, task.lastRun);
      })
      .catch((err) => {
        dispatch({ type: "CREATE_TASK_ERROR" }, err);
      });
  };
};

export const updateBotOpen = (botOpen, id) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks").doc(id);
    const userId = getState().firebase.auth.uid;
    const task = {
      features: botOpen,
      userId: userId,
      recurrencyTime: { number: 1, type: "minutes" },
      type: "OPEN_MATCH",
      lastRun: moment().unix(),
      updatedAt: moment().unix(),
      status: "ACTIVE",
    };

    taskRef
      .update(task)
      .then(() => {
        dispatch({ type: "UPDATE_TASK_SUCCESS" });

        updateLastRunData(firestore, id, task.lastRun);
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_TASK_ERROR" }, err);
      });
  };
};

export const updateBotStatus = (bot) => {
  return (dispatch, getState, { getFirestore }) => {
    console.log("try update", bot);
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks");
    const task = {
      status: bot.status,
    };

    taskRef
      .doc(bot.id)
      .set(task, { merge: true })
      .then(() => {
        console.log("sucess update");
        dispatch({ type: "UPDATE_TASK_SUCCESS" });
      })
      .catch((err) => {
        console.log("error update");
        dispatch({ type: "UPDATE_TASK_ERROR" }, err);
      });
  };
};

export const createBotOpenVolley = (botOpen) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks");
    const userId = getState().firebase.auth.uid;
    const task = {
      features: botOpen,
      userId: userId,
      recurrencyTime: { number: 1, type: "minutes" },
      type: "OPEN_MATCH_VOLLEY",
      lastRun: moment().unix(),
      createdAt: moment().unix(),
      status: "ACTIVE",
    };

    taskRef
      .add(task)
      .then((data) => {
        dispatch({ type: "CREATE_TASK_SUCCESS" });

        createLastRunData(firestore, data.id, task.lastRun);
      })
      .catch((err) => {
        dispatch({ type: "CREATE_TASK_ERROR" }, err);
      });
  };
};

export const updateBotOpenVolley = (botOpen, id) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks").doc(id);
    const userId = getState().firebase.auth.uid;
    const task = {
      features: botOpen,
      userId: userId,
      recurrencyTime: { number: 1, type: "minutes" },
      type: "OPEN_MATCH_VOLLEY",
      lastRun: moment().unix(),
      updatedAt: moment().unix(),
      status: "ACTIVE",
    };

    taskRef
      .update(task)
      .then(() => {
        dispatch({ type: "UPDATE_TASK_SUCCESS" });

        updateLastRunData(firestore, id, task.lastRun);
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_TASK_ERROR" }, err);
      });
  };
};

export const createBotOverGoals = (botOver) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks");
    const userId = getState().firebase.auth.uid;
    const task = {
      features: botOver,
      userId: userId,
      recurrencyTime: { number: 2, type: "minutes" },
      type: "OVER_GOALS",
      lastRun: moment().unix(),
      createdAt: moment().unix(),
      status: "ACTIVE",
    };

    taskRef
      .add(task)
      .then((data) => {
        dispatch({ type: "CREATE_TASK_SUCCESS" });

        createLastRunData(firestore, data.id, task.lastRun);
      })
      .catch((err) => {
        dispatch({ type: "CREATE_TASK_ERROR" }, err);
      });
  };
};

export const updateBotOverGoals = (botOver, id) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks").doc(id);
    const userId = getState().firebase.auth.uid;
    const task = {
      features: botOver,
      userId: userId,
      recurrencyTime: { number: 2, type: "minutes" },
      type: "OVER_GOALS",
      lastRun: moment().unix(),
      updatedAt: moment().unix(),
      status: "ACTIVE",
    };

    taskRef
      .update(task)
      .then(() => {
        dispatch({ type: "UPDATE_TASK_SUCCESS" });

        updateLastRunData(firestore, id, task.lastRun);
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_TASK_ERROR" }, err);
      });
  };
};

export const createBotUnderGoals = (botOver) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks");
    const userId = getState().firebase.auth.uid;
    const task = {
      features: botOver,
      userId: userId,
      recurrencyTime: { number: 2, type: "minutes" },
      type: "UNDER_GOALS",
      lastRun: moment().unix(),
      createdAt: moment().unix(),
      status: "ACTIVE",
    };

    taskRef
      .add(task)
      .then((data) => {
        dispatch({ type: "CREATE_TASK_SUCCESS" });

        createLastRunData(firestore, data.id, task.lastRun);
      })
      .catch((err) => {
        dispatch({ type: "CREATE_TASK_ERROR" }, err);
      });
  };
};

export const updateBotUnderGoals = (botOver, id) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks").doc(id);
    const userId = getState().firebase.auth.uid;
    const task = {
      features: botOver,
      userId: userId,
      recurrencyTime: { number: 2, type: "minutes" },
      type: "UNDER_GOALS",
      lastRun: moment().unix(),
      updatedAt: moment().unix(),
      status: "ACTIVE",
    };

    taskRef
      .update(task)
      .then(() => {
        dispatch({ type: "UPDATE_TASK_SUCCESS" });

        updateLastRunData(firestore, id, task.lastRun);
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_TASK_ERROR" }, err);
      });
  };
};

export const createBotOpenBetFair = (botOver) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks");
    const userId = getState().firebase.auth.uid;
    const task = {
      features: botOver,
      userId: userId,
      recurrencyTime: { number: 2, type: "minutes" },
      type: "OPEN_MATCHS_BETFAIR",
      lastRun: moment().unix(),
      createdAt: moment().unix(),
      status: "ACTIVE",
    };

    taskRef
      .add(task)
      .then((data) => {
        dispatch({ type: "CREATE_TASK_SUCCESS" });

        createLastRunData(firestore, data.id, task.lastRun);
      })
      .catch((err) => {
        dispatch({ type: "CREATE_TASK_ERROR" }, err);
      });
  };
};

export const updateBotOpenBetFair = (botOver, id) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks").doc(id);
    const userId = getState().firebase.auth.uid;
    const task = {
      features: botOver,
      userId: userId,
      recurrencyTime: { number: 2, type: "minutes" },
      type: "OPEN_MATCHS_BETFAIR",
      lastRun: moment().unix(),
      updatedAt: moment().unix(),
      status: "ACTIVE",
    };

    taskRef
      .update(task)
      .then(() => {
        dispatch({ type: "UPDATE_TASK_SUCCESS" });

        updateLastRunData(firestore, id, task.lastRun);
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_TASK_ERROR" }, err);
      });
  };
};

export const createBotOpenMolly = (botOver) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks");
    const userId = getState().firebase.auth.uid;
    const task = {
      features: botOver,
      userId: userId,
      recurrencyTime: { number: 2, type: "minutes" },
      type: "OPEN_MATCHS_MOLLY",
      lastRun: moment().unix(),
      createdAt: moment().unix(),
      status: "ACTIVE",
    };

    taskRef
      .add(task)
      .then((data) => {
        dispatch({ type: "CREATE_TASK_SUCCESS" });

        createLastRunData(firestore, data.id, task.lastRun);
      })
      .catch((err) => {
        dispatch({ type: "CREATE_TASK_ERROR" }, err);
      });
  };
};

export const updateBotOpenMolly = (botOver, id) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks").doc(id);
    const userId = getState().firebase.auth.uid;
    const task = {
      features: botOver,
      userId: userId,
      recurrencyTime: { number: 2, type: "minutes" },
      type: "OPEN_MATCHS_MOLLY",
      lastRun: moment().unix(),
      updatedAt: moment().unix(),
      status: "ACTIVE",
    };

    taskRef
      .update(task)
      .then(() => {
        dispatch({ type: "UPDATE_TASK_SUCCESS" });

        updateLastRunData(firestore, id, task.lastRun);
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_TASK_ERROR" }, err);
      });
  };
};

export const deleteBot = (id, getPlan) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks").doc(id);

    taskRef.delete().then(() => {
      dispatch({ type: "UPDATE_TASK_SUCCESS" });
      if (getPlan) {
        getPlan();
      }
    });
  };
};

export const createBotOpenFifa = (botOver) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks");
    const userId = getState().firebase.auth.uid;
    const task = {
      features: botOver,
      userId: userId,
      recurrencyTime: { number: 2, type: "minutes" },
      type: "OPEN_MATCHS_FIFA",
      lastRun: moment().unix(),
      createdAt: moment().unix(),
      status: "ACTIVE",
    };

    taskRef
      .add(task)
      .then((data) => {
        dispatch({ type: "CREATE_TASK_SUCCESS" });

        createLastRunData(firestore, data.id, task.lastRun);
      })
      .catch((err) => {
        dispatch({ type: "CREATE_TASK_ERROR" }, err);
      });
  };
};

export const updateBotOpenFifa = (botOver, id) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks").doc(id);
    const userId = getState().firebase.auth.uid;
    const task = {
      features: botOver,
      userId: userId,
      recurrencyTime: { number: 2, type: "minutes" },
      type: "OPEN_MATCHS_FIFA",
      lastRun: moment().unix(),
      updatedAt: moment().unix(),
      status: "ACTIVE",
    };

    taskRef
      .update(task)
      .then(() => {
        dispatch({ type: "UPDATE_TASK_SUCCESS" });

        updateLastRunData(firestore, id, task.lastRun);
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_TASK_ERROR" }, err);
      });
  };
};

export const createCustomBot = (customBot, oddFilter) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks");
    const userId = getState().firebase.auth.uid;
    const task = {
      features: customBot,
      userId: userId,
      recurrencyTime: { number: 1, type: "minutes" },
      type: "CUSTOM_BOT",
      lastRun: moment().unix(),
      createdAt: moment().unix(),
      status: "ACTIVE",
      oddFilter,
    };

    taskRef
      .add(task)
      .then((data) => {
        dispatch({ type: "CREATE_TASK_SUCCESS" });

        createLastRunData(firestore, data.id, task.lastRun);
      })
      .catch((err) => {
        dispatch({ type: "CREATE_TASK_ERROR" }, err);
      });
  };
};

export const updateCustomBot = (customBot, oddFilter, id) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const taskRef = firestore.collection("tasks").doc(id);
    const userId = getState().firebase.auth.uid;
    const task = {
      features: customBot,
      userId: userId,
      recurrencyTime: { number: 1, type: "minutes" },
      type: "CUSTOM_BOT",
      lastRun: moment().unix(),
      updatedAt: moment().unix(),
      status: "ACTIVE",
      oddFilter,
    };

    taskRef
      .update(task)
      .then(() => {
        dispatch({ type: "UPDATE_TASK_SUCCESS" });

        updateLastRunData(firestore, id, task.lastRun);
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_TASK_ERROR" }, err);
      });
  };
};
