import React, { Component } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import PricingTable from "./PricingTable";

class CheckoutContainer extends Component {
  render() {
    return (
      <StripeProvider apiKey="pk_live_j6RXaCeVc9hAA3JvCXXm24aA">
        <Elements>
          <PricingTable />
        </Elements>
      </StripeProvider>
    );
  }
}

export default CheckoutContainer;
