import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RulesListItems = ({ rulesList, deleteRule }) => {
  if (rulesList.length === 0) {
    return <React.Fragment>Nenhum regra adicionada ao robô</React.Fragment>;
  }

  const getRuleLabel = (rule) => {
    if (rule.attribute === "goalsLine") {
      return `A partida tenha ${getTypeLabel(rule.type)} ${getValueLabel(
        rule
      )} em ${getAttributeLabel(rule.attribute)}`;
    }

    if (
      rule.attribute === "goalsAdvantage" &&
      (rule.team === "teamBoth" || rule.team === "teamAny")
    ) {
      return `A partida tenha ${getTypeLabel(rule.type)} ${getValueLabel(
        rule
      )} em gols de diferença`;
    }

    if (rule.team === "teamBoth") {
      return `${getTeamLabel(rule.team)} tiverem ${getTypeLabel(
        rule.type
      )} ${getValueLabel(rule)} em ${getAttributeLabel(rule.attribute)}`;
    }

    return `${getTeamLabel(rule.team)} tiver ${getTypeLabel(
      rule.type
    )} ${getValueLabel(rule)} em ${getAttributeLabel(rule.attribute)}`;
  };

  const getTeamLabel = (team) => {
    const teams = {
      teamAny: "Um dos times",
      teamHome: "time da casa",
      teamBoth: "ambos os times",
      teamAway: "time visitante",
    };
    return teams[team];
  };

  const getTypeLabel = (type) => {
    const types = {
      less: "menos de",
      lessEqual: "menos ou igual a",
      equal: "exatamente",
      moreEqual: "mais ou igual a",
      more: "mais de",
    };
    return types[type];
  };

  const getAttributeLabel = (attribute) => {
    const attributesLabels = {
      goals: "gols",
      goalChances: "chances de gol",
      shots: "chutes totais",
      shotsOn: "chutes no gol",
      shotsOff: "chutes para fora",
      possession: "posse de bola",
      corners: "escanteios",
      attacks: "ataques",
      attacksDanger: "ataques perigosos",
      attacksPerMinute: "ataques perigosos por minuto",
      yellowCards: "cartões amarelos",
      redCards: "cartões vermelhos",
      handicap: "handicap inicial",
      goalsLine: "linha de gols",
      goalsAdvantage: "gols de vantagem",
    };

    return attributesLabels[attribute];
  };

  const getValueLabel = (rule) => {
    const value = Number(rule.value);
    const hasDecimal = value % 1 !== 0;
    const valueToRender = hasDecimal ? value.toFixed(2) : value;

    if (rule.attribute === "possession") {
      return `${valueToRender}%`;
    }

    return valueToRender;
  };

  const rulesRender = rulesList.map((rule, index) => {
    return (
      <div className="rule-container" key={rule.key}>
        <div>
          {getRuleLabel(rule).charAt(0).toUpperCase() +
            getRuleLabel(rule).slice(1) +
            "."}
        </div>
        <FontAwesomeIcon
          icon="minus-circle"
          color="red"
          onClick={(e) => deleteRule(e, index)}
        />
      </div>
    );
  });

  return rulesRender;
};

export default RulesListItems;
