export const signIn = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().languageCode = "pt";

    firebase
      .auth()
      .signInWithRedirect(provider)
      .then(resp => {
        console.log(resp);
      })
      .then(() => {
        dispatch({ type: "LOGIN_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "LOGIN_ERROR", err });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "SIGNOUT_SUCCESS" });
      });
  };
};

export const setSource = (uid, source) => {
  return (dispatch, getState, { getFirestore }) => {
    try {
      const db = getFirestore();

      db.collection("sources")
        .doc(uid)
        .set({ uid: uid, source: source });
    } catch (err) {}
  };
};
