export const descriptionByType = {
  CORNERS: {
    description: "Robô de escanteios",
  },
  OPEN_MATCH: {
    description: "Robô de abertura de futebol",
  },
  OPEN_MATCH_VOLLEY: {
    description: "Robô de abertura de vôlei",
  },
  OVER_GOALS: {
    description: "Robô de mais gols",
  },
  UNDER_GOALS: {
    description: "Robô de menos gols",
  },
  OPEN_MATCHS_BETFAIR: {
    description: "Robô de abertura BetFair",
  },
  OPEN_MATCHS_MOLLY: {
    description: "Robô de abertura Molly",
  },
  OPEN_MATCHS_FIFA: {
    description: "Robô de abertura FIFA",
  },
  CUSTOM_BOT: {
    description: "Robô customizado",
  },
};

export const statusClassByStatus = {
  ACTIVE: {
    className: "card-list-item-status--active",
  },
  INACTIVE: {
    className: "card-list-item-status--active",
  },
};
