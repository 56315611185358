import React, { Component } from "react";
import { injectStripe } from "react-stripe-elements";
import { connect } from "react-redux";
import { Mixpanel } from "../constants/mixpanel";

class PricingTable extends Component {
  constructor(props) {
    super(props);
    this.redirectToVIP = this.redirectToVIP.bind(this);
    this.redirectToVIPTri = this.redirectToVIPTri.bind(this);
    this.redirectToVIPSemester = this.redirectToVIPSemester.bind(this);
  }
  redirectToVIP(ev) {
    ev.preventDefault();
    console.log(this.props.stripe);
    this.props.stripe
      .redirectToCheckout({
        items: [{ plan: "price_1NXy1sBB0SabJ4LTedUHsbDE", quantity: 1 }],
        clientReferenceId: this.props.auth.uid,
        customerEmail: this.props.auth.email,
        successUrl: "https://app.betbots.com.br/sucesso",
        cancelUrl: "https://app.betbots.com.br/cancelado",
        locale: "pt",
      })
      .then(function (result) {
        if (result.error) {
          console.loge(result.error);
        }
      });
  }

  redirectToVIPTri(ev) {
    ev.preventDefault();
    console.log(this.props.stripe);
    this.props.stripe
      .redirectToCheckout({
        items: [{ plan: "price_1NXSAYBB0SabJ4LTnTdOZbRH", quantity: 1 }],
        clientReferenceId: this.props.auth.uid,
        customerEmail: this.props.auth.email,
        successUrl: "https://app.betbots.com.br/sucesso",
        cancelUrl: "https://app.betbots.com.br/cancelado",
        locale: "pt",
      })
      .then(function (result) {
        if (result.error) {
          console.loge(result.error);
        }
      });
  }

  redirectToVIPSemester(ev) {
    ev.preventDefault();
    console.log(this.props.stripe);
    this.props.stripe
      .redirectToCheckout({
        items: [{ plan: "price_1O6vy9BB0SabJ4LTpvvYJLVW", quantity: 1 }],
        clientReferenceId: this.props.auth.uid,
        customerEmail: this.props.auth.email,
        successUrl: "https://app.betbots.com.br/sucesso",
        cancelUrl: "https://app.betbots.com.br/cancelado",
        locale: "pt",
      })
      .then(function (result) {
        if (result.error) {
          console.loge(result.error);
        }
      });
  }

  componentDidMount() {
    Mixpanel.track("Visitou tabela de preços");
  }

  render() {
    return (
      <div>
        <div className="text-center">
          <h2>Conheça nosso plano VIP</h2>
          <div>
            *A renovação do plano é automática, para cancelar entre em contato
            pelo chat
          </div>
        </div>
        <div className="checkout">
          <div class="cards">
            <div class="card shadow">
              <ul>
                <li class="pack">Mensal</li>
                <li id="basic" class="price bottom-bar">
                  <span>R$</span>74
                </li>
                <li class="bottom-bar">Todas as vantagens do VIP</li>
                <li>
                  <button class="btn" onClick={this.redirectToVIP}>
                    Assinar mensal
                  </button>
                </li>
              </ul>
            </div>
            <div class="card active">
              <ul>
                <li class="pack">
                  VIP <br /> Trimestral
                </li>

                <li class="discount1">
                  <span>De R$ 222 </span>
                  por apenas
                </li>
                <li id="professional" class="price">
                  <span>R$</span>
                  <div>149</div>
                </li>
                <li class="discount1 padding">73 reais de desconto!</li>

                <li>
                  <button className="button-30" onClick={this.redirectToVIPTri}>
                    ASSINAR AGORA
                  </button>
                </li>
                <li class="discount"> 3 meses pelo preço de 2</li>
                <li class="bottom-bar">Bot de escanteios 1º tempo</li>
                <li class="bottom-bar">Bot de de mais/menos gols</li>
                <li class="bottom-bar">Custom Bot</li>
                <li class="">Acesso antecipado a novos bots</li>
              </ul>
            </div>
            <div class="card shadow">
              <ul>
                <li class="pack">Semestral</li>
                <li class="discount1">
                  De <span> R$ 444 </span> por apenas
                </li>
                <li id="master" class="price">
                  <span>R$</span>299
                </li>
                <li class="discount "> 6 meses pelo preço de 4</li>
                <li class="bottom-bar">Todas as vantagens do VIP</li>
                <li>
                  <a
                    class="btn"
                    href="https://forms.gle/WqVZRbFpq9ToL3eD8"
                    target="_blank"
                  >
                    Assinar Semestral com boleto
                  </a>
                </li>
                <li>
                  <button class="btn" onClick={this.redirectToVIPSemester}>
                    Assinar Semestral com cartão
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

export default connect(mapStateToProps)(injectStripe(PricingTable));
