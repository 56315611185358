import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Mixpanel } from "../constants/mixpanel";

const botsOptions = [
  {
    id: "BetBotsBot01_bot",
    apiKey: "910707773:AAH474-3GXHLMT8JRIKFgAkgKHN3xEZ3zeY",
  },
  {
    id: "BetBotsBot02_bot",
    apiKey: "934866398:AAEGgBaRpqqVlGVpD4Xw4bTwk2ROG75zDv8",
  },
  {
    id: "BetBotsBot03_bot",
    apiKey: "774242678:AAERgC_N6EaEP6T-8ykOwGiaswRUr5-oHk0",
  },
  {
    id: "BetBotsBot04_bot",
    apiKey: "890783891:AAGiZWMU7zoEEMqD5JeRsM-4PLR2Ah6ItII",
  },
  {
    id: "BetBotsBot05_bot",
    apiKey: "957079959:AAFJ71F0m6QNIAcwY_5g0XilHvDyEDdXvVg",
  },
];

const randomBot = botsOptions[Math.floor(Math.random() * botsOptions.length)];

const ChatsCardBlankslate = ({ id }) => {
  const handleTelegram = () => {
    Mixpanel.track("Tentou integrar com Telegram");
    window.open(`https://telegram.me/${randomBot.id}?start=${id}`, "_blank");
  };
  return (
    <div className="card card-filled">
      <div className="card-title">
        Chats
        <FontAwesomeIcon icon="comment" />
      </div>
      <div className="row vertical-offset-3 center-xs">
        <div className="col-xs-12">
          <p>Você não tem nenhum chat cadastrado para receber mensagens.</p>
        </div>
        <div className="col-xs-12 col-lg-6 vertical-offset-3 flex-justify-center">
          <button className="btn btn-inverted" onClick={handleTelegram}>
            Conectar ao Telegram
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatsCardBlankslate;
