import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createBotCorner,
  updateBotCorner,
  deleteBot,
} from "../store/actions/taskActions";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import InputRange from "react-input-range";
import RadioToolbar from "./RadioToolbar";
import "react-input-range/lib/css/index.css";
import SweetAlert from "sweetalert2-react";
import { Redirect } from "react-router";
import { Mixpanel } from "../constants/mixpanel";
import BotLayout from "./BotLayout";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { getPlanStatus as getPlanStatusAction } from "../store/actions/planAction";

class BotCornerNew extends Component {
  state = {
    description: "",
    half: "SECOND",
    fav: "LOSING",
    cornersFT: 8,
    handicap: 0.5,
    period: {
      max: 90,
      min: 75,
    },
    redirect: false,
    redirectToCheckout: false,
    successMessage: false,
    vipModal: false,
    defaultMax: 90,
    defaultMin: 45,
    minAttacks: 1,
    minShotsOn: 1,
    minPossession: 50,
    minShotsOff: 1,
    disabledCheck: true,
    isOnlyWithCornersMarket: false,
    leagues: [],
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleHalfChange = (changeEvent) => {
    let newHalf = changeEvent.target.value;
    if (newHalf === "FIRST") {
      this.setState({
        half: newHalf,
        period: { max: 40, min: 30 },
      });
    }
    if (newHalf === "SECOND") {
      this.setState({
        half: newHalf,
        period: { max: 80, min: 70 },
      });
    }
  };
  handleFavChange = (changeEvent) => {
    Mixpanel.track("Alterou favorito no robo de escanteios");
    this.setState({
      fav: changeEvent.target.value,
    });
  };
  handleOnlyWithCornersMarketChange = (changeEvent) => {
    this.setState({
      isOnlyWithCornersMarket: changeEvent.target.checked,
    });
  };
  updatePlanState = () => {
    this.props.getPlanStatus(this.props.auth.uid);
  };
  submitForm = (event) => {
    event.preventDefault();
    Mixpanel.track("Salvou robo com sucesso");

    if (this.props.match.params.id) {
      this.props.updateBotCorner(this.state, this.updatePlanState);
    } else {
      this.props.createBotCorner(this.state, this.updatePlanState);
    }
    this.setState({ successMessage: true });
  };
  deleteBot = (event) => {
    event.preventDefault();
    this.props.deleteBot(this.props.match.params.id, this.updatePlanState);
    this.setState({ deleteMessage: true });
  };
  redirectToDashboard = () => {
    this.setState({
      redirect: true,
    });
  };
  redirectToCheckout = () => {
    this.setState({
      redirectToCheckout: true,
    });
  };
  closeVipModal = () => {
    this.setState({
      vipModal: false,
    });
  };
  componentWillReceiveProps(props) {
    if (props.task && props.task.features) {
      let features = {};
      features.description = props.task.features.description || "";
      features.half = props.task.features.half || "SECOND";
      features.fav = props.task.features.fav || "LOSING";
      features.cornersFT =
        props.task.features.cornersFT > -1 ? props.task.features.cornersFT : 8;
      features.handicap = props.task.features.handicap || 0.5;
      features.period = props.task.features.period || { min: 75, max: 90 };
      features.minAttacks = props.task.features.minAttacks || 1;
      features.minPossession = props.task.features.minPossession || 58;
      features.minShotsOn = props.task.features.minShotsOn || 4;
      features.minShotsOff = props.task.features.minShotsOff || 8;
      features.leagues = props.task.features.leagues || [];
      features.isOnlyWithCornersMarket =
        props.task.features.isOnlyWithCornersMarket || false;

      this.setState({
        description: features.description,
        half: features.half,
        fav: features.fav,
        cornersFT: features.cornersFT,
        handicap: features.handicap,
        period: features.period,
        minAttacks: features.minAttacks,
        minPossession: features.minPossession,
        minShotsOn: features.minShotsOn,
        minShotsOff: features.minShotsOff,
        leagues: features.leagues,
        isOnlyWithCornersMarket: features.isOnlyWithCornersMarket,
      });
    }
  }
  render() {
    const animatedComponents = makeAnimated();
    Mixpanel.track("Visitou novo robo de escanteio");
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    if (this.state.redirectToCheckout) {
      return <Redirect to="/checkout" />;
    }
    if (this.state.successMessage) {
      Mixpanel.track("Criou/atualizou robo com sucesso");
      return (
        <SweetAlert
          show={true}
          title="Robô salvo"
          text="Em breve você receberá alertas dele"
          confirmButtonClass="btn btn-primary btn-large"
          buttonsStyling={false}
          type="success"
          onConfirm={this.redirectToDashboard}
        />
      );
    }

    if (this.state.deleteMessage) {
      Mixpanel.track("Excluiu robo com sucesso");
      return (
        <SweetAlert
          show={true}
          title="Robô excluído"
          confirmButtonClass="btn btn-primary btn-large"
          buttonsStyling={false}
          type="warning"
          onConfirm={this.redirectToDashboard}
        />
      );
    }

    if (
      this.props.plan &&
      this.props.plan.botSize > 1 &&
      this.props.plan.status !== "active" &&
      (!this.props.task || !this.props.task.createdAt)
    ) {
      return <Redirect to="/checkout" />;
    }

    let inputRange = null;
    if (this.state.half == "FIRST") {
      inputRange = (
        <InputRange
          value={this.state.period}
          id="period"
          onChange={(value) => this.setState({ period: value })}
          formatLabel={(value) => `${value} mins`}
          maxValue={45}
          minValue={1}
          draggableTrack
        />
      );
    } else {
      inputRange = (
        <InputRange
          value={this.state.period}
          id="period"
          onChange={(value) => this.setState({ period: value })}
          formatLabel={(value) => `${value} mins`}
          maxValue={90}
          minValue={45}
          draggableTrack
        />
      );
    }

    let fisrtHalfRadio = null;
    if (this.props.plan.status === "active") {
      fisrtHalfRadio = (
        <React.Fragment>
          <input
            type="radio"
            id="FIRST"
            name="HALF"
            value="FIRST"
            onChange={this.handleHalfChange}
            checked={this.state.half === "FIRST"}
          />
          <label htmlFor="FIRST">1º tempo</label>
        </React.Fragment>
      );
    } else {
      fisrtHalfRadio = (
        <React.Fragment>
          <div className="only-vip" onClick={this.redirectToCheckout}>
            1º tempo <div className="label-vip">somente VIPs</div>
          </div>
        </React.Fragment>
      );
    }

    let disabledCheck = true;

    if (this.props.plan && this.props.plan.status === "active") {
      disabledCheck = false;
    }

    let deleteButton = null;
    if (this.props.match.params.id) {
      deleteButton = (
        <button onClick={this.deleteBot} className="btn btn-outline">
          Excluir
        </button>
      );
    }

    return (
      <div>
        <h2>Configure o robô de cantos:</h2>
        <BotLayout>
          <div className="card">
            <p>
              Essas condições que a partida deve ter para o robô enviar o
              alerta.
            </p>
            <form>
              <h4>Nome</h4>
              <div className="form-group-input">
                <label>De um nome para sua estratégia:</label>
                <input
                  className="effect-1"
                  type="text"
                  placeholder="Robô de escanteios"
                  onChange={(event) =>
                    this.setState({ description: event.target.value })
                  }
                  value={this.state.description}
                />
                <span className="focus-border" />
              </div>
              <div className="form-group">
                <label>Em que período? </label>
                <RadioToolbar>
                  {fisrtHalfRadio}
                  <input
                    type="radio"
                    id="SECOND"
                    name="HALF"
                    value="SECOND"
                    onChange={this.handleHalfChange}
                    checked={this.state.half === "SECOND"}
                  />
                  <label htmlFor="SECOND">2º tempo</label>
                </RadioToolbar>
              </div>

              <div className="form-group">
                <label>Entre quais minutos? </label>

                {inputRange}
              </div>

              <div className="form-group">
                <label>Pelo menos quantos escanteios o jogo deve ter?</label>
                <InputRange
                  id="cornersFT"
                  value={this.state.cornersFT}
                  onChange={(value) => this.setState({ cornersFT: value })}
                  maxValue={20}
                  minValue={0}
                  draggableTrack
                />
              </div>

              <div className="form-group">
                <label>Qual situação o jogo deve estar?</label>
                <RadioToolbar>
                  <input
                    type="radio"
                    id="LOSING"
                    name="LOSING"
                    value="LOSING"
                    onChange={this.handleFavChange}
                    checked={this.state.fav === "LOSING"}
                  />
                  <label htmlFor="LOSING">Favorito perdendo</label>
                  <input
                    type="radio"
                    id="EVEN"
                    name="EVEN"
                    value="EVEN"
                    onChange={this.handleFavChange}
                    checked={this.state.fav === "EVEN"}
                  />
                  <label htmlFor="EVEN">Favorito perdendo ou empatando</label>
                </RadioToolbar>
              </div>

              <div className="form-group">
                <label>
                  Quão favorito um dos times deve ser? (handicap inicial)
                </label>
                <InputRange
                  id="handicap"
                  value={this.state.handicap}
                  onChange={(value) => this.setState({ handicap: value })}
                  maxValue={5}
                  minValue={0.25}
                  draggableTrack
                  step={0.25}
                  formatLabel={(value) => "ah -" + value.toFixed(2)}
                />
              </div>
              <div className="form-group form-vip">
                <label>
                  Configurações avançadas
                  <span
                    className="label-inline-vip"
                    onClick={this.redirectToCheckout}
                  >
                    Somente Vips
                  </span>
                </label>

                <div className="form-group">
                  <label>
                    Pelo menos quantos ataques perigosos o jogo deve ter? (Soma
                    dos times)
                  </label>
                  <InputRange
                    id="minAttacks"
                    value={this.state.minAttacks}
                    onChange={(value) => this.setState({ minAttacks: value })}
                    maxValue={150}
                    minValue={1}
                    draggableTrack
                    disabled={disabledCheck}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Pelo menos quantos chutes no gol deve ter? (Soma dos times)
                  </label>
                  <InputRange
                    id="minShotsOn"
                    value={this.state.minShotsOn}
                    onChange={(value) => this.setState({ minShotsOn: value })}
                    maxValue={30}
                    minValue={1}
                    draggableTrack
                    disabled={disabledCheck}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Pelo menos quantos chutes no total deve ter? (Soma dos
                    times)
                  </label>
                  <InputRange
                    id="minShotsOff"
                    value={this.state.minShotsOff}
                    onChange={(value) => this.setState({ minShotsOff: value })}
                    maxValue={30}
                    minValue={1}
                    draggableTrack
                    disabled={disabledCheck}
                  />
                </div>
                <div className="form-group">
                  <label>Minímo da posse de bola do time com mais posse</label>
                  <InputRange
                    id="minPossession"
                    value={this.state.minPossession}
                    formatLabel={(value) => `${value} %`}
                    onChange={(value) =>
                      this.setState({ minPossession: value })
                    }
                    maxValue={100}
                    minValue={50}
                    draggableTrack
                    disabled={disabledCheck}
                  />
                </div>
                <div className="form-group">
                  <label>Ligas</label>
                  <Select
                    components={animatedComponents}
                    placeholder="Selecione as ligas que seu robô vai olhar"
                    isMulti
                    options={this.props.scorebing_leagues}
                    value={this.state.leagues}
                    onChange={(value) => this.setState({ leagues: value })}
                    isDisabled={disabledCheck}
                  />
                  <small>Deixe em branco para selecionar todas as ligas</small>
                </div>

                <div className="form-group">
                  <div className="checkbox-container">
                    <input
                      type="checkbox"
                      name="onlyWithCornersMarket"
                      id="onlyWithCornersMarket"
                      disabled={disabledCheck}
                      checked={this.state.isOnlyWithCornersMarket}
                      onChange={this.handleOnlyWithCornersMarketChange}
                    />
                    <label htmlFor="onlyWithCornersMarket">
                      Somente jogos com mercado de escanteios disponível
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-8 col-sm-2">
                  <button onClick={this.submitForm} className="btn btn-primary">
                    Salvar
                  </button>
                </div>

                <div className="col-xs-4 col-sm-2">{deleteButton}</div>
              </div>
            </form>
          </div>
        </BotLayout>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let idProject = ownProps.match.params.id;
  let task = {};
  let scorebing_leagues = {};
  if (state.firestore.data.tasks) {
    task = state.firestore.data.tasks[idProject];
  }

  if (state.firestore.data.scorebing_leagues) {
    scorebing_leagues = state.firestore.data.scorebing_leagues;
  }

  var result = Object.keys(scorebing_leagues).map(function (key) {
    return { value: key, label: scorebing_leagues[key].league_name };
  });

  return {
    auth: state.firebase.auth,
    task: task,
    scorebing_leagues: result,
    plan: state.plan,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  let idProject = ownProps.match.params.id;
  console.log("id project", idProject);

  return {
    createBotCorner: (botCorner, getPlan) =>
      dispatch(createBotCorner(botCorner, getPlan)),
    deleteBot: (botId, getPlan) => dispatch(deleteBot(botId, getPlan)),
    updateBotCorner: (botCorner, getPlan) =>
      dispatch(updateBotCorner(botCorner, idProject, getPlan)),
    getPlanStatus: (uid) => dispatch(getPlanStatusAction(uid)),
  };
};
export default compose(
  firestoreConnect((props) => {
    let idProject = props.match.params.id;

    if (!idProject) {
      return [
        {
          collection: "scorebing_leagues",
        },
      ];
    } else {
      return [
        {
          collection: "tasks",
          doc: idProject,
        },
        {
          collection: "scorebing_leagues",
        },
      ];
    }
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(BotCornerNew);
