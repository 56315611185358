import React from "react";
import ChatsCardBlankslate from "./ChatsCardBlankslate";
import ChatsCardList from "./ChatsCardList";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";

const ChatsCard = ({ chats, id }) => {
  const hasChats = chats && chats.length > 0;

  if (!chats) {
    return (
      <div>
        <div className="card">
          <ReactPlaceholder
            type="text"
            rows={4}
            ready={!!chats}
            howLoadingAnimation={true}
          >
            <div />
          </ReactPlaceholder>
        </div>
      </div>
    );
  }

  if (!hasChats) {
    return (
      <div>
        <ChatsCardBlankslate id={id} />
      </div>
    );
  }

  return (
    <div>
      <ChatsCardList chats={chats} />
    </div>
  );
};

export default ChatsCard;
