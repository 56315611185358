export const getPlanStatus = (uid) => {
  return async (dispatch, _, { getFirestore }) => {
    try {
      const db = getFirestore();

      const usersRef = db.collection("users");
      const tasksRef = db.collection("tasks");
      const subscriptionsRef = db.collection("subscriptions");
      let status = null;

      usersRef
        .doc(uid)
        .get()
        .then((user) => {
          if (!user.exists) {
            tasksRef
              .where("userId", "==", uid)
              .get()
              .then((snapshot) => {
                dispatch({
                  type: "GET_PLAN_SUCCESS",
                  status: null,
                  size: snapshot.size,
                });
              });
          } else {
            let stripeID = user.data().stripeId || "";

            if (stripeID) {
              subscriptionsRef
                .where("stripeId", "==", stripeID)
                .get()
                .then((snapshot) => {
                  const promises = [];

                  snapshot.forEach((subscription) => {
                    promises.push(
                      tasksRef
                        .where("userId", "==", uid)
                        .get()
                        .then((snapshot) => {
                          status = subscription.data().status;
                          return {
                            status,
                            size: snapshot.size,
                          };
                        })
                    );
                  });

                  Promise.all(promises).then((subscriptions) => {
                    const size = subscriptions[0].size;
                    const activeSubscription = subscriptions.find(
                      (sub) => sub.status === "active"
                    );

                    if (activeSubscription) {
                      dispatch({
                        type: "GET_PLAN_SUCCESS",
                        status: activeSubscription.status,
                        size,
                      });
                    } else {
                      dispatch({
                        type: "GET_PLAN_SUCCESS",
                        status: null,
                        size,
                      });
                    }
                  });
                });
            } else {
              tasksRef
                .where("userId", "==", uid)
                .get()
                .then((snapshot) => {
                  dispatch({
                    type: "GET_PLAN_SUCCESS",
                    status: null,
                    size: snapshot.size,
                  });
                });
            }
          }
        });
    } catch (err) {
      console.log("rror");
    }
  };
};

export const activatePlan = (uid) => {
  return async (dispatch, _, { getFirestore }) => {
    try {
      dispatch({
        type: "RESET_PLAN_STATE",
      });

      const db = getFirestore();

      const usersRef = db.collection("users");
      const subscriptionsRef = db.collection("subscriptions");
      const tasksRef = db.collection("tasks");

      const user = await usersRef.doc(uid).get();
      const tasks = await tasksRef.where("userId", "==", uid).get();
      const size = tasks.size;

      if (user.exists) {
        const stripeId = user.data().stripeId;
        if (stripeId) {
          const subscriptions = await subscriptionsRef
            .where("stripeId", "==", stripeId)
            .get();

          subscriptions.forEach((sub) => {
            sub.ref.update({ status: "active" }).then(() => {
              dispatch({
                type: "GET_PLAN_SUCCESS",
                status: "active",
                size,
              });
            });
          });
        } else {
          dispatch({
            type: "GET_PLAN_SUCCESS",
            status: null,
            size,
          });
        }
      } else {
        dispatch({
          type: "GET_PLAN_SUCCESS",
          status: null,
          size,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
};
