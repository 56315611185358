import React, { useEffect } from "react";
import { connect } from "react-redux";
import check from "../assets/check.svg";
import { Link } from "react-router-dom";
import { Mixpanel } from "../constants/mixpanel";
import { activatePlan } from "../store/actions/planAction";

const SuccessPayment = (props) => {
  const isLoadingPlan = props.plan.plan === null;

  useEffect(() => {
    Mixpanel.track("Sucesso no pagamento");
    // eslint-disable-next-line no-undef
    gtag("event", "conversion", {
      send_to: "AW-999557009/ng1tCLXIs-QDEJGP0NwD",
      transaction_id: props.auth.email,
    });
  }, []);

  useEffect(() => {
    props.activatePlan(props.auth.uid);
  }, []);

  return (
    <div className="row center-xs">
      <div className="col-sm-12 col-md-6">
        <div className="card center">
          <h2>Pagamento efetuado com sucesso, bem-vindo ao VIP</h2>
          <img className="vertical-offset-3" width="120px" src={check} />
          <div className="center-xs center">
            <Link to={isLoadingPlan ? "/sucesso" : "/new-bot-type"}>
              <button
                className={`btn btn-primary vertical-offset-3 ${
                  isLoadingPlan ? "btn-disabled-color" : ""
                }`}
              >
                Criar novo robô
              </button>
            </Link>
            <Link to={isLoadingPlan ? "/sucesso" : "/dashboard"}>
              <button
                className={`btn btn-outline vertical-offset-1 ${
                  isLoadingPlan ? "btn-disabled-color" : ""
                }`}
              >
                Voltar para o dashboard
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    plan: state.plan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activatePlan: (uid) => dispatch(activatePlan(uid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPayment);
