import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createBotUnderGoals,
  updateBotUnderGoals,
  deleteBot,
} from "../store/actions/taskActions";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import SweetAlert from "sweetalert2-react";
import { Redirect } from "react-router";
import { Mixpanel } from "../constants/mixpanel";
import Select from "react-select";
import makeAnimated from "react-select/animated";

class BotUnderGoals extends Component {
  state = {
    period: { min: 1, max: 40 },
    maxCorners: 3,
    maxAttacks: 10,
    minGoals: 1,
    maxGoals: 4,
    minPossession: 58,
    maxShotsOn: 4,
    maxShotsOff: 8,
    minRedCards: 0,
    description: "",
    isDraw: false,
    notDraw: false,
    redirect: false,
    successMessage: false,
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };
  submitForm = (event) => {
    event.preventDefault();
    Mixpanel.track("Salvou robo de under gols com sucesso");
    if (this.props.match.params.id) {
      this.props.updateBot(this.state, this.props.match.params.id);
    } else {
      this.props.createBot(this.state);
    }
    this.setState({ successMessage: true });
  };
  deleteBot = (event) => {
    event.preventDefault();
    this.props.deleteBot(this.props.match.params.id);
    this.setState({ deleteMessage: true });
  };
  redirectToDashboard = () => {
    this.setState({
      redirect: true,
    });
  };
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };
  componentWillReceiveProps(props) {
    if (props.task && props.task.features) {
      let features = {};
      features.period = props.task.features.period || { min: 1, max: 40 };
      features.maxCorners = props.task.features.maxCorners || 0;
      features.maxAttacks = props.task.features.maxAttacks || 10;
      features.minGoals = props.task.features.minGoals || 0;
      features.maxGoals = props.task.features.maxGoals || 4;
      features.minPossession = props.task.features.minPossession || 58;
      features.maxShotsOn = props.task.features.maxShotsOn || 4;
      features.maxShotsOff = props.task.features.maxShotsOff || 8;
      features.minRedCards = props.task.features.minRedCards || 0;
      features.isDraw = props.task.features.isDraw || false;
      features.description = props.task.features.description || "";
      features.notDraw = props.task.features.notDraw || false;
      features.leagues = props.task.features.leagues || [];

      this.setState({
        period: features.period,
        maxCorners: features.maxCorners,
        maxAttacks: features.maxAttacks,
        minGoals: features.minGoals,
        maxGoals: features.maxGoals,
        minPossession: features.minPossession,
        maxShotsOn: features.maxShotsOn,
        maxShotsOff: features.maxShotsOff,
        minRedCards: features.minRedCards,
        isDraw: features.isDraw,
        notDraw: features.notDraw,
        description: features.description,
        leagues: features.leagues,
      });
    }
  }
  render() {
    const animatedComponents = makeAnimated();
    if (
      this.props.plan &&
      this.props.plan.status != "active" &&
      this.props.match.path === "/new-under-goals-bot"
    ) {
      return <Redirect to="/checkout" />;
    }

    Mixpanel.track("Visitou novo robo de under goals");
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    if (this.state.successMessage) {
      Mixpanel.track("Criou/atualizou robo de under goals com sucesso");
      return (
        <SweetAlert
          show={true}
          title="Robô salvo"
          text="Em breve você receberá alertas dele"
          confirmButtonClass="btn btn-primary btn-large"
          buttonsStyling={false}
          type="success"
          onConfirm={this.redirectToDashboard}
        />
      );
    }

    if (this.state.deleteMessage) {
      Mixpanel.track("Excluiu robo com sucesso");
      return (
        <SweetAlert
          show={true}
          title="Robô excluído"
          confirmButtonClass="btn btn-primary btn-large"
          buttonsStyling={false}
          type="warning"
          onConfirm={this.redirectToDashboard}
        />
      );
    }

    let deleteButton = null;
    if (this.props.match.params.id) {
      deleteButton = (
        <button onClick={this.deleteBot} className="btn btn-outline">
          Excluir
        </button>
      );
    }

    return (
      <div>
        <h2>Configure o robô de menos gols:</h2>
        <div className="row">
          <div className="card">
            <p>
              Você será alertado quando um jogo novo aparecer nas condições
              configuradas aqui:
            </p>
            <form class="under-goals">
              <h4>Nome</h4>
              <div className="form-group-input">
                <label>De um nome para sua estratégia:</label>
                <input
                  class="effect-1"
                  type="text"
                  placeholder="Robô de under goals"
                  onChange={(event) =>
                    this.setState({ description: event.target.value })
                  }
                  value={this.state.description}
                />
                <span class="focus-border" />
              </div>
              <h4>Período</h4>
              <div className="form-group">
                <label>Entre quais minutos?</label>
                <InputRange
                  value={this.state.period}
                  id="period"
                  onChange={(value) => this.setState({ period: value })}
                  formatLabel={(value) => `${value} mins`}
                  maxValue={90}
                  minValue={0}
                  draggableTrack
                />
              </div>
              <h4>Movimentos ofensivos</h4>
              <div className="form-group">
                <label>
                  No máximo quantos ataques perigosos o jogo deve ter? (Soma dos
                  times)
                </label>
                <InputRange
                  id="maxAttacks"
                  value={this.state.maxAttacks}
                  onChange={(value) => this.setState({ maxAttacks: value })}
                  maxValue={150}
                  minValue={1}
                  draggableTrack
                />
              </div>
              <div className="form-group">
                <label>
                  No máximo quantos chutes no gol deve ter? (Soma dos times)
                </label>
                <InputRange
                  id="maxShotsOn"
                  value={this.state.maxShotsOn}
                  onChange={(value) => this.setState({ maxShotsOn: value })}
                  maxValue={30}
                  minValue={1}
                  draggableTrack
                />
              </div>
              <div className="form-group">
                <label>
                  No máximo quantos chutes no total deve ter? (Soma dos times)
                </label>
                <InputRange
                  id="maxShotsOff"
                  value={this.state.maxShotsOff}
                  onChange={(value) => this.setState({ maxShotsOff: value })}
                  maxValue={30}
                  minValue={1}
                  draggableTrack
                />
              </div>
              <div className="form-group">
                <label>
                  No máximo quantos escanteios o jogo deve ter? (Soma dos times)
                </label>
                <InputRange
                  id="maxCorners"
                  value={this.state.maxCorners}
                  onChange={(value) => this.setState({ maxCorners: value })}
                  maxValue={20}
                  minValue={0}
                  draggableTrack
                />
              </div>
              <div className="form-group">
                <label>Minímo da posse de bola do time com mais posse</label>
                <InputRange
                  id="minPossession"
                  value={this.state.minPossession}
                  formatLabel={(value) => `${value} %`}
                  onChange={(value) => this.setState({ minPossession: value })}
                  maxValue={100}
                  minValue={50}
                  draggableTrack
                />
              </div>
              <h4>Gols</h4>
              <div className="form-group">
                <label>
                  Pelo menos quantos gols o jogo deve ter? (Soma dos times)
                </label>
                <InputRange
                  id="minGoals"
                  value={this.state.minGoals}
                  onChange={(value) => this.setState({ minGoals: value })}
                  maxValue={10}
                  minValue={0}
                  draggableTrack
                />
              </div>
              <div className="form-group">
                <label>No máximo gols o jogo deve ter? (Soma dos times)</label>
                <InputRange
                  id="maxGoals"
                  value={this.state.maxGoals}
                  onChange={(value) => this.setState({ maxGoals: value })}
                  maxValue={10}
                  minValue={1}
                  draggableTrack
                />
              </div>
              <h4>Outros</h4>
              <div className="form-group">
                <label>Minímo de cartões vermelhos? (Soma dos times)</label>
                <InputRange
                  id="minRedCards"
                  value={this.state.minRedCards}
                  onChange={(value) => this.setState({ minRedCards: value })}
                  maxValue={5}
                  minValue={0}
                  draggableTrack
                />
              </div>

              <div className="form-group">
                <label>Ligas</label>
                <Select
                  components={animatedComponents}
                  placeholder="Selecione as ligas que seu robô vai olhar"
                  isMulti
                  options={this.props.scorebing_leagues}
                  value={this.state.leagues}
                  onChange={(value) => this.setState({ leagues: value })}
                />
                <small>Deixe em branco para selecionar todas as ligas</small>
              </div>

              <div className="form-group">
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="isDraw"
                    id="isDraw"
                    checked={this.state.isDraw}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="spainList">Somente jogos empatados</label>
                </div>
              </div>
              <div className="form-group">
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="notDraw"
                    id="notDraw"
                    checked={this.state.notDraw}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="notDraw">Somente jogos não empatados</label>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-8 col-sm-2">
                  <button onClick={this.submitForm} className="btn btn-primary">
                    Salvar
                  </button>
                </div>

                <div className="col-xs-4 col-sm-2">{deleteButton}</div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let idProject = ownProps.match.params.id;
  let task = {};
  let scorebing_leagues = {};
  if (state.firestore.data.tasks) {
    task = state.firestore.data.tasks[idProject];
  }

  if (state.firestore.data.scorebing_leagues) {
    scorebing_leagues = state.firestore.data.scorebing_leagues;
  }

  const result = Object.keys(scorebing_leagues).map(function (key) {
    return { value: key, label: scorebing_leagues[key].league_name };
  });

  return {
    auth: state.firebase.auth,
    scorebing_leagues: result,
    task: task,
    plan: state.plan,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  let idProject = ownProps.match.params.id;
  return {
    createBot: (botCorner) => dispatch(createBotUnderGoals(botCorner)),
    deleteBot: (botId) => dispatch(deleteBot(botId)),
    updateBot: (botCorner) =>
      dispatch(updateBotUnderGoals(botCorner, idProject)),
  };
};

export default compose(
  firestoreConnect((props) => {
    let idProject = props.match.params.id;

    if (!idProject) {
      return [
        {
          collection: "scorebing_leagues",
        },
      ];
    } else {
      return [
        {
          collection: "tasks",
          doc: idProject,
        },
        {
          collection: "scorebing_leagues",
        },
      ];
    }
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(BotUnderGoals);
