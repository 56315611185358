import React, { Component } from "react";

const BotLayout = props => {
  return (
    <div className="row">
      <div class="col-md-7 col-sm-12 ">{props.children}</div>
    </div>
  );
};

export default BotLayout;
